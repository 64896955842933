/* eslint-disable import/no-named-as-default-member */
import React, {
  ElementRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import Phaser from 'phaser'
import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'

import { useSize } from '../../hooks/useSize'
import { Sparkles } from '../../types/global'

import * as SC from './styled'
import { SparklesScene } from './phaser'

export type HeartProps = {
  className?: string
  id?: string
  color?: string
  text?: string
  sparkles?: Sparkles
  isStaticSparkles?: boolean
  onClick?: () => any
  isTextOnly?: boolean
  onSparklesPhaserScene?: (phaserScene: SparklesScene) => void
}

const Heart = forwardRef<ElementRef<'div'> | null, HeartProps>(function Heart(props, ref) {
  const {
    className,
    id,
    color,
    text,
    sparkles,
    isStaticSparkles = true,
    isTextOnly = false,
    onClick,
  } = props

  // REFS
  const $sparklesPhaserParent = useRef<ElementRef<'div'> | null>(null)
  const $sparklesPhaserGame = useRef<Phaser.Game | null>(null)
  const $sparklesPhaserScene = useRef<SparklesScene | null>(null)
  const $container = useRef<ElementRef<'div'> | null>(null)

  useImperativeHandle<
    ElementRef<'div'> | null,
    ElementRef<'div'> | null
  >(ref, () => $container.current)

  // STATE

  const [isPhaserSparklesReady, setIsPhaserSparklesReady] = useState(false)

  // EFFECT

  const size = useSize($container)

  // if sparkles and not static -> create phaser sparkles
  useEffect(() => {
    if (sparkles && !isStaticSparkles && !$sparklesPhaserGame.current) {
      $sparklesPhaserScene.current = new SparklesScene({ sparkles })
      $sparklesPhaserGame.current = new Phaser.Game({
        type: Phaser.AUTO,
        parent: $sparklesPhaserParent.current as HTMLElement,
        width: ($sparklesPhaserParent.current?.clientWidth ?? 0) * window.devicePixelRatio,
        height: ($sparklesPhaserParent.current?.clientHeight ?? 0) * window.devicePixelRatio,
        scene: $sparklesPhaserScene.current,
        transparent: true,
      })

      $sparklesPhaserGame.current.events.on(Phaser.Core.Events.READY, () => {
        $sparklesPhaserScene.current!.events.on(Phaser.Scenes.Events.CREATE, () => {
          setIsPhaserSparklesReady(true)
        })
      })
    }
  }, [isStaticSparkles, sparkles])

  // update sparkles on pahser scene
  useEffect(() => {
    if (sparkles && isPhaserSparklesReady && $sparklesPhaserScene.current) {
      $sparklesPhaserScene.current?.setSparkles(sparkles)
      $sparklesPhaserScene.current?.playAnim()
    }
  }, [isPhaserSparklesReady, sparkles])

  return (
    <SC.Container ref={$container} className={className} id={id} $color={color} onClick={onClick}>
      <SC.TextContainer>
        <SC.ShapeLeft />
        <SC.ShapeRight />
        {text && (
          <SC.Text $fontSize={size?.width ? `${(size.width / 550) * 20}px` : undefined}>
            <span>{text}</span>
          </SC.Text>
        )}
      </SC.TextContainer>
      {!isTextOnly && (
        <>
          <SC.HeartIcon />
          {isStaticSparkles && (
            <>
              <SwitchTransition>
                <Transition
                  key={sparkles ?? 'none'}
                  addEndListener={(node, done) => {
                    node?.addEventListener('gsapComplete', done, false)
                  }}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  onEnter={(node: HTMLElement) => {
                    gsap.fromTo(
                      node,
                      {
                        opacity: 0,
                      },
                      {
                        duration: 0.3,
                        ease: 'power1.out',
                        opacity: 1,
                      }
                    )
                  }}
                  onExit={(node: HTMLElement) => {
                    gsap.to(node, {
                      duration: sparkles ? 0.2 : 0.01,
                      ease: 'power1.out',
                      opacity: 0,
                      onComplete: () => {
                        node?.dispatchEvent(new Event('gsapComplete'))
                      },
                    })
                  }}
                >
                  <div>
                    {sparkles && (
                      <>
                        <SC.SparklesLeft src={`/images/sparkles/${sparkles}.png`} alt="sparkles" />
                        <SC.SparklesRight src={`/images/sparkles/${sparkles}.png`} alt="sparkles" />
                      </>
                    )}
                  </div>
                </Transition>
              </SwitchTransition>
            </>
          )}
          <SC.SparklesPhaser ref={$sparklesPhaserParent} $visible={!!sparkles} />
        </>
      )}
    </SC.Container>
  )
})

export default Heart
