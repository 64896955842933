const values = {
  mobile: 375,
  tablet: 768,
  tabletLarge: 1365,
  desktop: 1440,
}
const up = (val: keyof typeof values) => `@media (min-width: ${values?.[val]}px)`
const down = (val: keyof typeof values) => `@media (max-width: ${values?.[val]}px)`

const breakpoints = {
  values,
  up,
  down,
}

export default breakpoints
