import React, { FC, InputHTMLAttributes, useId } from 'react'

import * as SC from './styled'

export type ToggleButtonProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  label?: string
  checked?: boolean
  children?: React.ReactElement
  textColor?: string
  checkedTextColor?: string
  backgroundColor?: string
  checkedBackgroundColor?: string
  checkedBorderColor?: string
}

const ToggleButton: FC<ToggleButtonProps> = ({
  className,
  label,
  children,
  disabled,
  textColor = 'black',
  checkedTextColor = 'white',
  backgroundColor = 'white',
  checkedBackgroundColor = 'black',
  checkedBorderColor = 'black',
  ...checkbox
}) => {
  const uniqueId = useId()

  return (
    <SC.Wrapper
      className={className}
      htmlFor={uniqueId}
      $checked={checkbox.checked}
      $disabled={disabled}
      $backgroundColor={backgroundColor}
      $checkedBackgroundColor={checkedBackgroundColor}
      $checkedBorderColor={checkedBorderColor}
    >
      <SC.Checkbox id={uniqueId} type={'checkbox'} disabled={disabled} {...checkbox} />
      {label && (
        <SC.Label
          $checked={checkbox.checked}
          $disabled={disabled}
          $textColor={textColor}
          $checkedTextColor={checkedTextColor}
        >
          {label}
        </SC.Label>
      )}
      {children}
    </SC.Wrapper>
  )
}

export default ToggleButton
