import * as Phaser from 'phaser'

import type { Game } from '../Game'
import Heart from '../objects/Heart'
import { Color, Declaration, Sparkles, WolType } from '../../types/global'

import CollisionStartEvent = Phaser.Physics.Matter.Events.CollisionStartEvent

const configurations = {
  [WolType.smallPodium]: {
    gravity: -0.04,
  },
  [WolType.largePodium]: {
    gravity: -0.08,
  },
}

export default class MainScene extends Phaser.Scene {
  game: Game
  activeHearts: Array<Heart>
  heartInitialWidth: number
  lastSpawTime = 0
  spawnInterval = 1000
  spawnMinDistanceToAnotherHeart: number
  spawnMaxTry = 10
  isSpawnActive = true
  isHeartsCollisionEffectEnabled = false
  ceiling?: MatterJS.BodyType
  // scene plugin
  // matterCollision: typeof PhaserMatterCollisionPlugin;

  // scene variables
  isActive = true

  constructor({ game }: { game: Game }) {
    console.log('MainScene: constructor')
    super({})
    this.game = game
    this.activeHearts = []

    this.heartInitialWidth = this.game.canvas.width * 0.2
    this.spawnMinDistanceToAnotherHeart = this.heartInitialWidth * 1.1

    // set initial gravity
    this.game.config.physics.matter!.gravity = {
      y: configurations[this.game.wolType].gravity * this.game.pixelRatio,
    }
  }

  createHeart({
    x,
    y,
    color,
    declaration,
    sparkles,
  }: {
    x: number
    y: number
    color: Color
    declaration: Declaration
    sparkles?: Sparkles
  }) {
    const shapes = this.cache.json.get('shapes')
    this.activeHearts.push(
      new Heart({
        scene: this,
        x,
        y,
        color,
        sparkles,
        declarationId: declaration,
        initialWidth: this.heartInitialWidth,
        options: {
          shape: shapes.heart,
        },
      })
    )
  }

  createCeiling() {
    this.ceiling = this.matter.add.rectangle(
      this.game.canvas.width / 2,
      -this.heartInitialWidth,
      this.game.canvas.width,
      1,
      {
        isStatic: true,
        isSensor: true,
      }
    )
  }

  getActiveHeart(uuid: string) {
    return this.activeHearts.find((heart) => heart.matterSprite?.getData('uuid') === uuid)
  }

  destroyHeart(uuid: string) {
    // get heart to destroy
    const heartToDestroy = this.getActiveHeart(uuid)
    // remove heart from activeHeart
    this.activeHearts = this.activeHearts.filter(
      (heart) => heart.matterSprite?.getData('uuid') !== uuid
    )
    // destroy heart
    heartToDestroy?.destroy()
  }

  getSpawnPosition = (): { x: number; y: number } | null => {
    let tryCount = 0
    const needsNewPosition = (x: number, y: number): boolean => {
      for (let i = 0; i < this.activeHearts.length; i++) {
        const activeHeart = this.activeHearts[i]
        const distance = Phaser.Math.Distance.Between(
          x,
          y,
          activeHeart.matterSprite!.x,
          activeHeart.matterSprite!.y
        )

        if (distance < this.spawnMinDistanceToAnotherHeart) {
          return true
        }
      }
      return false
    }

    const generateXY = (): { x: number; y: number } | null => {
      tryCount = tryCount + 1

      if (tryCount > this.spawnMaxTry) {
        return null
      }

      const x = Phaser.Math.Between(
        this.heartInitialWidth / 2,
        this.game.canvas.width - this.heartInitialWidth / 2
      )

      const y = this.game.canvas.height + this.heartInitialWidth / 2

      if (needsNewPosition(x, y)) {
        return generateXY()
      }
      return { x, y }
    }

    return generateXY()
  }

  updateWolConfiguration() {
    this.matter?.world.setGravity(
      0,
      configurations[this.game.wolType].gravity * this.game.pixelRatio
    )

    this.activeHearts.forEach((heart) => {
      heart.updateWolConfiguration()
    })
  }

  // ---------- EVENTS ----------

  addEventsListeners() {
    // if debug enabled: listen click to spawn heart
    if (this.matter.world.drawDebug) {
      this.input.on('pointerdown', (pointer: Phaser.Input.Pointer) => {
        this.createHeart({
          x: pointer.x,
          y: pointer.y,
          color: Color.iconic,
          declaration: Declaration.myself,
          sparkles: Sparkles.orange,
        })
      })
    }

    this.matter.world.on('collisionstart', (event: CollisionStartEvent) => {
      event.pairs.forEach((pair) => {
        const { bodyA, bodyB } = pair

        // Detecting collisions between ceiling and anything else
        if (bodyA === this.ceiling || bodyB === this.ceiling) {
          const heartBody = bodyA === this.ceiling ? bodyB : bodyA
          if (heartBody) {
            this.destroyHeart(heartBody.gameObject.getData('uuid'))
          }
        }

        // Detecting collisions between 2 hearts
        if (bodyA?.gameObject?.getData('isHeart') && bodyB?.gameObject?.getData('isHeart')) {
          // get hearts instances from active hearts
          const heartA = this.getActiveHeart(bodyA.gameObject.getData('uuid'))
          const heartB = this.getActiveHeart(bodyB.gameObject.getData('uuid'))
          if (heartA && heartB) {
            heartA.onCollideWithAnotherHeart()
            heartB.onCollideWithAnotherHeart()
          }
        }
      })
    })

    this.events.on('destroy', () => {
      console.log('MainScene: destroy')
      this.isActive = false
    })

    this.events.on('shutdown', () => {
      console.log('MainScene: shutdown')
      this.isActive = false
    })
  }

  // ########## PHASER SCENE FUNCTIONS ##########

  preload() {
    console.log('MainScene: preload')
    // load heart
    this.load.image('heart', 'images/hearts/heart-white.png')
    // load body shapes
    this.load.json('shapes', 'static/shapes/shapes.json')
    // load sparkles
    this.load.multiatlas('sparkles', 'static/animations/sparkles.json', 'static/animations')
  }

  create() {
    console.log('MainScene: create')

    // create sparkles animations
    Object.values(Sparkles).forEach((sparkles) => {
      this.anims.create({
        key: `sparkles-${sparkles}`,
        frames: this.anims.generateFrameNames('sparkles', {
          prefix: `sparkles-${sparkles}/`,
          suffix: '.png',
          start: 0,
          end: 24,
          zeroPad: 2,
        }),
        frameRate: 15,
      })
    })

    this.addEventsListeners()
    this.createCeiling()
  }

  destroy() {
    this.isActive = false
    this.scene.stop()
    // this.matter.world.resetCollisionIDs()
    this.matter.world.destroy()
    // this.matterCollision.removeAllCollideListeners()
    this.scene.remove()
  }

  update(time: number, _delta: number) {
    // heart spawn
    if (this.isSpawnActive && time - this.lastSpawTime > this.spawnInterval) {
      this.lastSpawTime = time

      const nextHeartInQueue = this.game.heartsQueue[0]

      if (nextHeartInQueue) {
        const spawPosition = this.getSpawnPosition()

        if (spawPosition) {
          this.createHeart({
            x: spawPosition.x,
            y: spawPosition.y,
            color: nextHeartInQueue.color,
            declaration: nextHeartInQueue.declaration,
            sparkles: nextHeartInQueue.sparkles,
          })
          this.game.onHeartSpawn()
        }
      }
    }

    // update active heart
    this.activeHearts.forEach((heart) => {
      // update scale
      const initialScale = heart.initialWidth / heart.matterSprite!.width

      const yRatio = Phaser.Math.Clamp(
        Math.max(0.1, heart.matterSprite!.y + this.game.canvas.height * 0.2) /
          this.game.canvas.height,
        0,
        1
      )

      const minScaleFactor = 0.3

      heart.matterSprite?.setScale(
        initialScale *
          Phaser.Math.Linear(minScaleFactor, 1, Phaser.Math.Easing.Quadratic.Out(yRatio))
      )

      // update frictionAir based on yRatio
      const initialFrictionAir = 0.01
      heart.matterSprite?.setFrictionAir(
        initialFrictionAir / Phaser.Math.Linear(0.3, 1, Phaser.Math.Easing.Quadratic.Out(yRatio))
      )

      // update velocityX
      const offset = 0
      const amplitude = heart.velocityXAmplitude
      const frenquency = heart.velocityXFrenquency
      const velocityTime = time / 1000 - heart.velocityXTimeOffset
      const displacementAngle = 0

      const targetVelocityX =
        (offset +
          amplitude * Math.sin(2 * Math.PI * frenquency * velocityTime + displacementAngle)) *
        this.game.pixelRatio

      heart.matterSprite?.setVelocityX(
        (heart.matterSprite!.getVelocity().x ?? 0) + targetVelocityX / 80
      )

      // sync sparkles sprites
      heart.synchSparklesSprites()
    })
  }
}
