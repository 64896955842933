import { Color, Sparkles } from '../types/global'
import colors from '../theme/colors'

export const colorsHex = {
  [Color.iconic]: colors.gold,
  [Color.speranza]: colors.loveGratitude,
  [Color.rispetto]: colors.loveRespect,
  [Color.generosita]: colors.loveGenerosity,
  [Color.gratitudine]: colors.loveGratitude,
  [Color.affetto]: colors.loveAffection,
  [Color.felicita]: colors.loveHappiness,
  [Color.orgoglio]: colors.lovePride,
  [Color.devozione]: colors.loveDevotion,
  [Color.audacia]: colors.loveAudacity,
}

export const sparklesHex = {
  [Sparkles.citrus]: colors.citrus,
  [Sparkles.orange]: colors.orange,
  [Sparkles.vanilla]: colors.pureWhite,
}
