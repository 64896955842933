import styled from 'styled-components'

import WolPhaser from '../../components/WolPhaser'
import HeartCounter from '../../components/HeartCounter'
import Icon from '../../components/Icon'

export const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;
  position: relative;
`

export const Wol = styled(WolPhaser)``

export const LogoIcon = styled(Icon)`
  position: absolute;
  z-index: 10;
  left: 8rem;
  top: 8rem;
  color: ${(props) => props.theme.colors.gold};
  width: 300px;
`

export const HeartCount = styled(HeartCounter)`
  position: absolute;
  z-index: 10;
  display: inline-flex;
  right: 7rem;
  top: 7rem;
`

export const DebugContainer = styled.div`
  position: absolute;
  z-index: 10;
  display: inline-flex;
  left: 0;
  top: 0;
  gap: 20px;
  padding: 1rem;
  align-items: center;

  & label {
    ${({ theme }) => theme.textStyles.text};
    display: flex;
    gap: 10px;
    align-items: center;
    color: ${({ theme }) => theme.colors.gold};
    p {
    }
  }
`
