import React, { FC, useRef, useEffect, useCallback, useState } from 'react'
import * as Phaser from 'phaser'
import { Dispatch } from 'redux'
import html2canvas from 'html2canvas'

import { getGameConfig } from '../../phaser/configs/gameConfig'
import { Game } from '../../phaser/Game'
import { Declaration, HeartConfig, WolType } from '../../types/global'

import * as SC from './styled'

export type WolPhaserProps = {
  wolType?: WolType
  isActive?: boolean
  isDebug?: boolean
  hasBlurGradient?: boolean
  width?: number
  dispatch?: Dispatch
  currentLocale: string
  declarationLabels: { [key in Declaration]: string }
  heartsQueue: Array<HeartConfig>
  onHeartSpawn: () => void
}

const WolPhaser: FC<WolPhaserProps> = ({
  wolType = WolType.smallPodium,
  isActive = true,
  isDebug = false,
  hasBlurGradient = true,
  width = window.innerWidth,
  currentLocale,
  declarationLabels,
  dispatch,
  heartsQueue,
  onHeartSpawn,
}) => {
  // DOM REF

  const $parent = useRef<HTMLDivElement>(null)
  const $textTextureCanvas = useRef<{ [key: string]: HTMLCanvasElement }>({})

  // PHASER REF

  const $game = useRef<Game | null>(null)

  // STATE

  const [isGameReady, setIsGameReady] = useState(false)
  const [isTextTextureAdded, setIsTextTextureAdded] = useState(false)

  const createTextTexture = useCallback(async (element: HTMLElement) => {
    const existingCanvas = $textTextureCanvas.current[element.id]
    // if canvas already exists -> do nothing
    if (existingCanvas) {
      return
    }
    const canvas = await html2canvas(element, { backgroundColor: null })
    $game.current?.addTextTexture(element.id, canvas)
    $textTextureCanvas.current[element.id] = canvas
    return { id: element.id, canvas }
  }, [])

  const createTextTextures = useCallback(async () => {
    const container = document.querySelector(SC.HeartTexts.toString())
    const containerChildren = Array.from(container!.children) as HTMLElement[]

    await Promise.all(containerChildren.map((child) => createTextTexture(child)))

    setIsTextTextureAdded(true)
  }, [createTextTexture])

  // create phaser game

  const createGame = useCallback(() => {
    if (!$game.current) {
      const gameConfig = getGameConfig({
        height: window.innerHeight,
        width,
        parent: $parent.current!,
        pixelRatio: window.devicePixelRatio,
        debug: isDebug,
      })

      $game.current = new Game(gameConfig, {
        wolType,
        dispatch,
        pixelRatio: window.devicePixelRatio,
        heartsQueue,
        currentLocale,
        onHeartSpawn,
      })

      $game.current.events.on(Phaser.Core.Events.READY, () => {
        setIsGameReady(true)
      })
    }
  }, [currentLocale, dispatch, heartsQueue, isDebug, onHeartSpawn, width, wolType])

  // SIDE EFFECTS

  // on mount -> create game

  useEffect(() => {
    createGame()
  }, [createGame])

  // game ready -> create text texture
  // locale change -> create text texture

  useEffect(() => {
    if (isGameReady) {
      // wait for the text to render properly
      setTimeout(() => {
        createTextTextures()
      }, 500)
    }
  }, [createTextTextures, isGameReady, currentLocale])

  // on text texture loaded -> start scene

  useEffect(() => {
    if (isTextTextureAdded) {
      $game.current?.startMainScene()
    }
  }, [isTextTextureAdded])

  // update wolType

  useEffect(() => {
    if ($game.current) {
      $game.current?.setWolType(wolType)
    }
  }, [wolType])

  // update debug

  useEffect(() => {
    if ($game.current?.mainScene?.matter?.world) {
      $game.current.mainScene.matter.world.drawDebug = isDebug
    }
  }, [isDebug])

  // update heartsQueue

  useEffect(() => {
    if ($game.current) {
      $game.current.heartsQueue = heartsQueue
    }
  }, [heartsQueue])

  // update currentLocale

  useEffect(() => {
    if ($game.current) {
      $game.current.currentLocale = currentLocale
    }
  }, [currentLocale])

  // update onHeartSpawn

  useEffect(() => {
    if ($game.current) {
      $game.current.onHeartSpawn = onHeartSpawn
    }
  }, [onHeartSpawn])

  // update dispatch

  useEffect(() => {
    $game.current?.setDispatch(dispatch)
  }, [dispatch])

  // on unmount : stop game

  useEffect(() => {
    return () => {
      if ($game.current) {
        $game.current.destroy(true)
      }
    }
  }, [dispatch])

  useEffect(() => {
    if (isGameReady) {
      if (isActive) {
        $game.current?.resume()
      } else {
        $game.current?.pause()
      }
    }
  }, [isActive, isGameReady])

  // return

  return (
    <>
      <SC.PhaserGameParent className="game-phaser" ref={$parent}></SC.PhaserGameParent>
      {hasBlurGradient && (
        <>
          <SC.BlurGradient />
          <SC.ColorGradient />
        </>
      )}
      <SC.HeartTexts>
        {Object.keys(declarationLabels).map((key) => (
          <SC.HeartText
            key={key}
            id={`text-${currentLocale}-${key}`}
            isTextOnly
            text={declarationLabels[key as Declaration]}
            color={'white'}
          />
        ))}
      </SC.HeartTexts>
    </>
  )
}

export default WolPhaser
