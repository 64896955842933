import React, { FC } from 'react'

import { Icons } from '../../../Icon'

import * as SC from './styled'

export type FormFieldSelectProps = React.InputHTMLAttributes<HTMLSelectElement> & {
  className?: string
  label?: string
  name: string
  value?: string
  options: { label: string; value: string }[]
  help?: string
  error?: string
  hasError?: boolean
}

const FormFieldSelect: FC<FormFieldSelectProps> = (props) => {
  const { className, label, placeholder, options, help, error, hasError, ...selectProps } = props

  return (
    <SC.Container className={className} $isDisabled={selectProps.disabled}>
      {label && (
        <SC.Label required={selectProps.required} error={!!error || hasError}>
          {label}
        </SC.Label>
      )}
      <SC.SelectContainer>
        <SC.Select $error={!!error || hasError} {...selectProps}>
          {placeholder && (
            <option value="" disabled selected>
              {placeholder}
            </option>
          )}
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SC.Select>
        <SC.SelectArrow icon={Icons.chevronDown} />
      </SC.SelectContainer>
      {!error && help && <SC.Help>{help}</SC.Help>}
      {error && <SC.Error>{error}</SC.Error>}
    </SC.Container>
  )
}

export default FormFieldSelect
