import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { actions } from '../../../../redux'
import { Step } from '../../../../types/global'
import LandingTemplate from '../../../../templates/Landing'

const LandingStep = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  return (
    <LandingTemplate
      title={t('landing.title')}
      textTitle={t('landing.textTitle')}
      text={t('landing.text')}
      heart={{
        text: t('landing.heart.text'),
        onClick: () => {
          dispatch(actions.app.setCurrentStep(Step.selection))
        },
      }}
    />
  )
}

export default LandingStep
