import styled from 'styled-components'

import Icon from '../Icon'

export const Container = styled.div`
  color: ${(props) => props.theme.colors.gold};
  display: inline-flex;
  flex-direction: column;
`

export const Top = styled.div`
  display: flex;
  align-items: flex-end;
`

export const HeartIcon = styled(Icon)`
  width: 6rem;
  margin-bottom: 1.2rem;
`

export const Number = styled.p`
  ${({ theme }) => theme.textStyles.titleH3};
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.text};
  margin-left: auto;
`
