import React, { FC } from 'react'

import { IconProps } from '../Icon'
import { LinkProps } from '../Link'

import * as SC from './styled'

export type ActionButtonProps = LinkProps & {
  // TODO: keep only label or text
  label?: string
  text?: string
  isUppercase?: boolean
  isDisabled?: boolean
  iconProps?: IconProps
  iconPosition?: 'left' | 'right'
  variant?: 'primary' | 'primaryWithoutBackground' | 'primaryWithBackground'
  children?: React.ReactNode
}

const ActionButton: FC<ActionButtonProps> = ({
  label,
  text,
  isUppercase = true,
  isDisabled,
  children,
  iconProps,
  iconPosition,
  variant = 'primary',
  ...linkProps
}) => {
  return (
    <SC.ActionButton $variant={variant} {...linkProps} $disabled={isDisabled}>
      {iconProps && (!iconPosition || iconPosition === 'left') && <SC.ActionIcon {...iconProps} />}
      {(children || label || text) && (
        <SC.Label $isUppercase={isUppercase}>{children || label || text}</SC.Label>
      )}
      {iconProps && iconPosition === 'right' && <SC.ActionIcon {...iconProps} />}
    </SC.ActionButton>
  )
}

export default ActionButton
