import React, { FC } from 'react'

import { theme } from '../../theme'
import { HeartProps } from '../../components/Heart'

import * as SC from './styled'

export type SelectionTemplateProps = {
  className?: string
  iconic: {
    title: string
    cta: string
    heart?: HeartProps
    onClick?: () => void
  }
  personalize: {
    title: string
    cta: string
    heart?: HeartProps
    onClick?: () => void
  }
}

const SelectionTemplate: FC<SelectionTemplateProps> = ({ className, iconic, personalize }) => {
  return (
    <SC.Content className={className}>
      <SC.Item onClick={personalize.onClick}>
        <SC.ItemTitle>{personalize?.title}</SC.ItemTitle>
        <SC.ItemCta>{personalize.cta}</SC.ItemCta>
        <SC.ItemHeart color={theme.colors.loveGratitude} {...personalize.heart} />
      </SC.Item>
      <SC.Item onClick={iconic.onClick}>
        <SC.ItemTitle>{iconic?.title}</SC.ItemTitle>
        <SC.ItemCta>{iconic.cta}</SC.ItemCta>
        <SC.ItemHeart color={theme.colors.gold} {...iconic.heart} />
      </SC.Item>
    </SC.Content>
  )
}

export default SelectionTemplate
