import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { Step, HeartConfig } from '../../types/global'
import { getRandomHeartsConfig } from '../../helpers/HeartsHelpers'

import { actionTypes } from './types'

type AppState = {
  isInit: boolean
  isSmallPodium: boolean
  isInStore: boolean
  retailer?: string
  currentStep: Step
  heartsQueue: Array<HeartConfig>
}

//
// Initial state
//

const initialState: AppState = {
  isInit: false,
  isSmallPodium: window.hasExternalDisplay !== undefined ? !window.hasExternalDisplay : true,
  isInStore: false,
  retailer: undefined,
  currentStep: Step.landing,
  heartsQueue: window.initialHeartsQueue ?? getRandomHeartsConfig(10),
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    init: () => undefined,
    setIsInit: (state, action: actionTypes.setIsInit) => {
      const { isInit } = action.payload
      state.isInit = isInit
    },
    setIsSmallPodium: (state, action: PayloadAction<boolean>) => {
      state.isSmallPodium = action.payload
    },
    setIsInStore: (state, action: PayloadAction<boolean>) => {
      state.isInStore = action.payload
    },
    setRetailer: (state, action: PayloadAction<string | undefined>) => {
      state.retailer = action.payload
    },
    setCurrentStep: (state, action: PayloadAction<Step>) => {
      state.currentStep = action.payload
    },
    setHeartsQueue: (state, action: PayloadAction<AppState['heartsQueue']>) => {
      state.heartsQueue = action.payload
    },
    addHeartToQueue: (state, action: PayloadAction<HeartConfig>) => {
      state.heartsQueue = [action.payload, ...state.heartsQueue]
    },
    sendHeartToWol: (_state, _action: PayloadAction<HeartConfig>) => undefined,
    onHeartSpawn: (_state) => undefined,
    moveFirstHeartToEndOfQueue: (state) => {
      state.heartsQueue.push(state.heartsQueue.shift()!)
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const isInit = (state: RootState) => root(state).isInit
const isSmallPodium = (state: RootState) => root(state).isSmallPodium
const isInStore = (state: RootState) => root(state).isInStore
const retailer = (state: RootState) => root(state).retailer
const currentStep = (state: RootState) => root(state).currentStep
const heartsQueue = (state: RootState) => root(state).heartsQueue

export const selectors = {
  isInit,
  isInStore,
  retailer,
  isSmallPodium,
  currentStep,
  heartsQueue,
}
