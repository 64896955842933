import React, { FC } from 'react'
import cx from 'classnames'
import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'

import { ActionButtonProps } from '../../components/ActionButton'
import { HeartCounterProps } from '../../components/HeartCounter'
import { FormFieldSelectProps } from '../../components/form/fields/FormFieldSelect'

import * as SC from './styled'

export type DefaultLayoutProps = {
  className?: string
  backButton?: {
    isVisible: boolean
    props: ActionButtonProps
  }
  restartButton?: {
    isVisible: boolean
    props: ActionButtonProps
  }
  heartCounter?: {
    isVisible: boolean
    props: HeartCounterProps
  }
  children?: React.ReactNode
  localesSelect?: {
    isVisible: boolean
    props: FormFieldSelectProps
  }
}

const DefaultLayout: FC<DefaultLayoutProps> = ({
  children,
  className,
  backButton,
  restartButton,
  heartCounter,
  localesSelect,
}) => {
  return (
    <SC.Container className={cx(className)}>
      {children}
      <SC.LogoIcon icon="logo" />

      <Transition
        in={!!localesSelect?.isVisible}
        addEndListener={(node, done) => {
          node.addEventListener('gsapComplete', done, false)
        }}
        mountOnEnter={true}
        unmountOnExit={true}
        onEnter={(node: HTMLElement) => {
          gsap.fromTo(
            node,
            {
              opacity: 0,
            },
            {
              duration: 0.2,
              ease: 'power1.out',
              opacity: 1,
            }
          )
        }}
        onExit={(node: HTMLElement) => {
          gsap.to(node, {
            duration: 0.2,
            ease: 'power1.out',
            opacity: 0,
            onComplete: () => {
              node.dispatchEvent(new Event('gsapComplete'))
            },
          })
        }}
      >
        {localesSelect ? <SC.LocalesSelector {...localesSelect.props} /> : <div />}
      </Transition>

      <Transition
        in={!!backButton?.isVisible}
        addEndListener={(node, done) => {
          node.addEventListener('gsapComplete', done, false)
        }}
        mountOnEnter={true}
        unmountOnExit={true}
        onEnter={(node: HTMLElement) => {
          gsap.fromTo(
            node,
            {
              opacity: 0,
            },
            {
              duration: 0.2,
              ease: 'power1.out',
              opacity: 1,
            }
          )
        }}
        onExit={(node: HTMLElement) => {
          gsap.to(node, {
            duration: 0.2,
            ease: 'power1.out',
            opacity: 0,
            onComplete: () => {
              node.dispatchEvent(new Event('gsapComplete'))
            },
          })
        }}
      >
        <SC.BackButton
          {...backButton?.props}
          iconProps={{ icon: 'chevronLeft' }}
          variant="primaryWithoutBackground"
        />
      </Transition>

      <SwitchTransition>
        <Transition
          key={restartButton?.isVisible ? restartButton?.props.text : 'notVisible'}
          addEndListener={(node, done) => {
            node?.addEventListener('gsapComplete', done, false)
          }}
          mountOnEnter={true}
          unmountOnExit={true}
          onEnter={(node: HTMLElement) => {
            gsap.fromTo(
              node,
              {
                opacity: 0,
              },
              {
                duration: 0.2,
                ease: 'power1.out',
                opacity: 1,
              }
            )
          }}
          onExit={(node: HTMLElement) => {
            gsap.to(node, {
              duration: 0.2,
              ease: 'power1.out',
              opacity: 0,
              onComplete: () => {
                node?.dispatchEvent(new Event('gsapComplete'))
              },
            })
          }}
        >
          <>
            {restartButton?.isVisible && (
              <SC.RestartButton
                {...restartButton?.props}
                iconProps={{ icon: 'refresh' }}
                variant="primaryWithoutBackground"
              />
            )}
          </>
        </Transition>
      </SwitchTransition>

      <Transition
        in={!!heartCounter?.isVisible}
        addEndListener={(node, done) => {
          node.addEventListener('gsapComplete', done, false)
        }}
        mountOnEnter={true}
        unmountOnExit={true}
        onEnter={(node: HTMLElement) => {
          gsap.fromTo(
            node,
            {
              opacity: 0,
            },
            {
              duration: 0.2,
              ease: 'power1.out',
              opacity: 1,
            }
          )
        }}
        onExit={(node: HTMLElement) => {
          gsap.to(node, {
            duration: 0.2,
            ease: 'power1.out',
            opacity: 0,
            onComplete: () => {
              node.dispatchEvent(new Event('gsapComplete'))
            },
          })
        }}
      >
        <SC.HeartCount {...heartCounter?.props} />
      </Transition>
    </SC.Container>
  )
}

export default DefaultLayout
