import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import SelectionTemplate from '../../../../templates/Selection'
import { actions } from '../../../../redux'
import { Step } from '../../../../types/global'

const SelectionStep = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <SelectionTemplate
      iconic={{
        title: t('selection.iconic.title'),
        cta: t('selection.iconic.cta'),
        onClick: () => {
          dispatch(actions.app.setCurrentStep(Step.iconicShare))
        },
      }}
      personalize={{
        title: t('selection.personalize.title'),
        cta: t('selection.personalize.cta'),
        onClick: () => {
          dispatch(actions.app.setCurrentStep(Step.personalizationColor))
        },
      }}
    />
  )
}

export default SelectionStep
