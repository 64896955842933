import React, { ElementRef, FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { actions, selectors } from '../../../redux'
import Inactivity from '../../../components/Inactivity'
import { Step } from '../../../types/global'
import { trackingEventNames } from '../../../redux/tracking/constants'

const InactivityContainer: FC = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  // SELECTORS

  const timer = useSelector(selectors.inactivity.timer)
  const isSmallPodium = useSelector(selectors.app.isSmallPodium)
  const currentStep = useSelector(selectors.app.currentStep)

  // REFS

  const $container = useRef<ElementRef<'div'>>(null)

  // EFFECTS

  // start / stop timer
  useEffect(() => {
    switch (currentStep) {
      case Step.landing:
        if (isSmallPodium) {
          dispatch(actions.inactivity.startTimer())
        } else {
          dispatch(actions.inactivity.stopTimer())
        }
        break
      case Step.wolSmallPodium:
        dispatch(actions.inactivity.stopTimer())
        break
      case Step.selection:
      case Step.personalizationColor:
      case Step.personalizationText:
      case Step.personalizationSparkles:
      case Step.personalizationShare:
      case Step.iconicShare:
        dispatch(actions.inactivity.startTimer())
    }
  }, [currentStep, dispatch, isSmallPodium])

  // listen user activity to restart timer

  useEffect(() => {
    const onUserActivity = (event: Event) => {
      if ($container.current?.contains(event.target as Node)) {
        return
      }
      dispatch(actions.inactivity.resetTimer())
    }

    window.addEventListener('click', onUserActivity)
    window.addEventListener('touchstart', onUserActivity)

    return () => {
      window.removeEventListener('click', onUserActivity)
      window.removeEventListener('touchstart', onUserActivity)
    }
  }, [dispatch])

  // listen timer expiration
  useEffect(() => {
    if (timer <= 0) {
      dispatch(actions.app.setCurrentStep(isSmallPodium ? Step.wolSmallPodium : Step.landing))
      dispatch(actions.inactivity.resetTimer())
    }
  }, [dispatch, isSmallPodium, timer])

  if (timer > 20) {
    return null
  }

  return (
    <Inactivity
      ref={$container}
      title={t('inactivity.title')}
      text={t('inactivity.text', {
        second: timer,
      })}
      continueButton={{
        label: t('inactivity.continueButton'),
        onClick: () => {
          dispatch(actions.inactivity.resetTimer())
        },
      }}
      restartButton={{
        label: t('inactivity.restartButton'),
        onClick: () => {
          dispatch(
            actions.tracking.pushEvent({
              event: trackingEventNames.restartTheExperience,
            })
          )
          dispatch(actions.inactivity.resetTimer())
          dispatch(actions.app.setCurrentStep(Step.landing))
        },
      }}
    />
  )
}

export default InactivityContainer
