import { createGlobalStyle } from 'styled-components'

import { fontsFaces } from './fonts'

const GlobalStyles = createGlobalStyle`
  ${fontsFaces}
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    font-size: 62.5%;
    user-select: none;
  }

  html,
  body,
  #root {
    margin: 0;
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.colors.beige};
    color: ${(props) => props.theme.colors.pureBlack};
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
`

export default GlobalStyles
