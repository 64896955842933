import { REHYDRATE } from 'redux-persist'
import {
  all,
  call,
  fork,
  put,
  SagaReturnType,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects'

import { actions, selectors } from '..'
import AuthSagas from '../auth/sagas'
import { ReactNativeMessages, Step } from '../../types/global'

export default class AppSagas {
  static *init() {
    const isInit: SagaReturnType<typeof selectors.app.isInit> = yield select(selectors.app.isInit)
    yield call(AuthSagas.onInit) // must be first auth call to refresh token if needed
    yield fork(AppSagas.loadInitialData) // can be forked

    if (!isInit) {
      yield put(actions.app.setIsInit({ isInit: true }))
    }
  }

  static *loadInitialData() {
    yield all([])
  }

  static *sendHeartToWol(action: ReturnType<typeof actions.app.sendHeartToWol>) {
    // add it to heart queue
    yield put(actions.app.addHeartToQueue(action.payload))

    // send data to react native app
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        id: ReactNativeMessages.sendHeartToWol,
        payload: action.payload,
      })
    )

    const isSmallPodium: ReturnType<typeof selectors.app.isSmallPodium> = yield select(
      selectors.app.isSmallPodium
    )

    // if small podium -> show wol
    if (isSmallPodium) {
      yield put(actions.app.setCurrentStep(Step.wolSmallPodium))
    }
  }

  static *onHeartSpawn() {
    // move first heart to end of queue
    yield put(actions.app.moveFirstHeartToEndOfQueue())

    // send data to react native app
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        id: ReactNativeMessages.heartSpawn,
      })
    )
  }

  static *listeners() {
    yield all([
      takeLeading(REHYDRATE, this.init),
      takeEvery(actions.app.sendHeartToWol, this.sendHeartToWol),
      takeEvery(actions.app.onHeartSpawn, this.onHeartSpawn),
    ])
  }
}
