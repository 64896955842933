import fonts from './fonts'

const textStyles = {
  titleH1: `
    ${fonts.FuturaLTProLight}
    font-size: 12.8rem;
    text-transform: uppercase;
    line-height: 110%;
    letter-spacing: 0.04em;
  `,
  titleH2: `
    ${fonts.FuturaLTProBook}
    font-size: 4rem;
     line-height: 120%;
    letter-spacing: 0.04em;
  `,
  titleH3: `
    ${fonts.FuturaLTProBook}
    font-size: 3.2rem;
    line-height: 120%;
    letter-spacing: 0.04em;
  `,
  titleH4: `
    ${fonts.FuturaLTProMedium}
    font-size: 2rem;
    line-height: 140%;
    letter-spacing: 0.04em;
  `,
  titleH5: `
    ${fonts.FuturaLTProMedium}
    font-size: 2rem;
    line-height: initial;
    letter-spacing: 0.04em;
  `,
  titleH6: `
    ${fonts.FuturaLTProMedium}
    font-size: 1.6rem;
    line-height: initial;
    letter-spacing: 0.04em;
  `,
  titleH6Up: `
    ${fonts.FuturaLTProMedium}
    font-size: 1.4rem;
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  `,
  text: `
    ${fonts.FuturaLTProMedium}
    font-size: 2rem;
    line-height: 140%;
    letter-spacing: 0.04em;
  `,
  textSmall: `
   ${fonts.FuturaLTProMedium}
    font-size: 1.5rem;
    line-height: 120%;
    letter-spacing: 0.04em;
  `,
}

export default textStyles
