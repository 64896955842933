import React, { ElementRef, forwardRef } from 'react'

import { ActionButtonProps } from '../ActionButton'

import * as SC from './styled'

export type InactivityProps = {
  className?: string
  title: string
  text: string
  continueButton: ActionButtonProps
  restartButton: ActionButtonProps
}

const Inactivity = forwardRef<ElementRef<'div'>, InactivityProps>(function Inactivity(
  { className, title, text, restartButton, continueButton },
  ref
) {
  return (
    <SC.Inactivity className={className} ref={ref}>
      <SC.Container>
        <SC.Title>{title}</SC.Title>
        <SC.Text>{text}</SC.Text>
        <SC.Buttons>
          <SC.Button {...continueButton} variant="primaryWithBackground" />
          <SC.Button {...restartButton} variant="primary" />
        </SC.Buttons>
      </SC.Container>
    </SC.Inactivity>
  )
})

export default Inactivity
