import styled, { css } from 'styled-components'

import FormLabel from '../../FormLabel'
import Icon from '../../../Icon'

export const Container = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  flex-flow: column;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
    `}
`

export const Label = styled(FormLabel)`
  margin-bottom: 0.5rem;
`

export const SelectContainer = styled.div`
  position: relative;
`

export const Select = styled.select<{ $error?: boolean }>`
  display: block;
  width: 100%;
  padding: 1.2rem 2.4rem 1.2rem 1.2rem;
  ${(props) => props.theme.textStyles.text};
  color: ${(props) => props.theme.colors.pureBlack};
  border: 1px solid ${(props) => props.theme.colors.pureBlack};
  border-radius: 0;
  box-shadow: none;
  background-color: ${(props) => props.theme.colors.pureWhite};
  outline: none;
  appearance: none;
  cursor: pointer;

  ${(props) =>
    props.$error &&
    css`
      color: ${(props) => props.theme.colors.red};
      border-color: ${(props) => props.theme.colors.red};
    `}

  ${(props) =>
    !props.value &&
    css`
      color: #808080;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      user-select: none;
    `};

  & option[value=''][disabled] {
    display: none;
  }
`

export const SelectArrow = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
`

export const Error = styled.p`
  ${({ theme }) => theme.textStyles.text}
  color: ${({ theme }) => theme.colors.red};
  margin-top: 0.5rem;
`

export const Help = styled.p`
  ${({ theme }) => theme.textStyles.textSmall}
  color: grey;
  margin-top: 0.5rem;
`
