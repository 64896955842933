import useResizeObserver from '@react-hook/resize-observer'
import React, { useLayoutEffect, useState } from 'react'

export const useSize = <T extends HTMLElement>(target: React.RefObject<T>) => {
  const [size, setSize] = useState<DOMRectReadOnly>()

  useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect())
  }, [target])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}
