import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as htmlToImage from 'html-to-image'

import PersonalizationTemplate from '../../../../templates/Personalisation'
import { Color, Declaration, Step } from '../../../../types/global'
import { colorsHex } from '../../../../helpers/ColorsHelpers'
import { actions, selectors } from '../../../../redux'
import * as configuration from '../../../../configuration'
import { routesPath } from '../../../../router'
import useWindowSize from '../../../../hooks/useWindowSize'
import { theme } from '../../../../theme'
import { trackingEventNames } from '../../../../redux/tracking/constants'
import retailers from '../../../../data/retailers.json'

const IconicShareStep = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const isSmallPodium = useSelector(selectors.app.isSmallPodium)
  const currentStep = useSelector(selectors.app.currentStep)
  const isInStore = useSelector(selectors.app.isInStore)
  const retailer = useSelector(selectors.app.retailer)
  const [isSentToWol, setIsSentToWol] = useState(false)
  const [isSharePending, setIsSharePending] = useState(true)
  const [shareFileArray, setShareFileArray] = useState<File[]>()

  const windowSize = useWindowSize()

  const isMobile = useMemo(() => {
    return windowSize.width < theme.breakpoints.values.tablet
  }, [windowSize])

  useEffect(() => {
    if (isMobile) {
      setTimeout(async () => {
        const heartElement = document.querySelector('.share-heart') as HTMLElement
        const blob = await htmlToImage.toBlob(heartElement, {
          backgroundColor: theme.colors.beige,
          pixelRatio: 2,
          quality: 0.9,
        })
        const filesArray = [
          new File([blob!], 'heart.png', {
            type: blob?.type,
            lastModified: new Date().getTime(),
          }),
        ]

        setShareFileArray(filesArray)

        setIsSharePending(false)
      }, 600) // wait exit duration previous step + enter duration current step
    }
  }, [currentStep, isMobile])

  useEffect(() => {
    if (currentStep !== Step.iconicShare && currentStep !== Step.personalizationShare) {
      setIsSentToWol(false)
    }
  }, [currentStep])

  return (
    <PersonalizationTemplate
      heart={{
        color: colorsHex[Color.iconic],
        text: t('heart.text', {
          preposition: '',
          subject: '',
        }),
        // sparkles: Sparkles.orange,
      }}
      heartForSharing={
        isMobile
          ? {
              heart: {
                color: colorsHex[Color.iconic],
                text: t('heart.text', {
                  preposition: '',
                  subject: '',
                }),
              },
              containerClassName: 'share-heart',
            }
          : undefined
      }
      shareStep={{
        isVisible: true,
        isSmallPodium,
        qrCode: {
          // &s=${Sparkles.orange}
          url: `${configuration.app.WEBSITE_URL}${routesPath.shareLanding}?l=${i18n.language}${
            retailer ? `&r=${retailer}` : ''
          }&c=${Color.iconic}&d=${Declaration.iconic}`,
        },
        mobileButton: {
          label: t('share.mobile.shareButton.label'),
          isDisabled: isSharePending,
          onClick: () => {
            dispatch(
              actions.tracking.pushEvent({
                event: trackingEventNames.shareHeart,
              })
            )
            navigator.share({
              files: shareFileArray,
              text: t('sharing.text'),
              url: window.location.origin + `/#/?l=${i18n.language}`,
            })
          },
        },
        mobileLinkButton:
          isInStore && !retailer
            ? undefined
            : {
                label: t('share.mobile.linkButton.label', {
                  retailer: retailer
                    ? retailers.find((r) => r.id === retailer)?.name
                    : 'Dolce&Gabbana',
                }),
                href: retailer
                  ? retailers.find((r) => r.id === retailer)?.url
                  : t('share.mobile.linkButton.link'),
                target: '_blank',
                onClick: () => {
                  dispatch(
                    actions.tracking.pushEvent({
                      event: trackingEventNames.shopNow,
                    })
                  )
                },
              },
        mobileRestartButton: {
          label: t('share.mobile.restart'),
          onClick: () => {
            dispatch(
              actions.tracking.pushEvent({
                event: trackingEventNames.restartTheExperience,
              })
            )
            dispatch(actions.app.setCurrentStep(Step.landing))
          },
        },
        desktopButton: {
          label: t('share.shareButton.label'),
          successLabel: t('share.shareButton.label.success'),
          iSuccess: isSentToWol,
          onClick: () => {
            dispatch(
              actions.app.sendHeartToWol({
                color: Color.iconic,
                declaration: Declaration.iconic,
                // sparkles: Sparkles.orange,
              })
            )
            setIsSentToWol(true)
          },
        },
        qrCodeText: t('share.qrCode.text'),
        socials: {
          label: t('share.socials.label'),
          items: [
            {
              icon: {
                icon: 'instagram',
              },
              link: {
                href: t('share.socials.intagram.link'),
                target: '_blank',
                onClick: () => {
                  dispatch(
                    actions.tracking.pushEvent({
                      event: trackingEventNames.followDGBeauty,
                    })
                  )
                },
              },
            },
          ],
        },
      }}
    />
  )
}

export default IconicShareStep
