import React, { ElementRef, FC, useRef } from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'

import { HeartProps } from '../../components/Heart'
import { ToggleButtonProps } from '../../components/ToggleButton'
import { ActionButtonProps } from '../../components/ActionButton'
import { QrCodeProps } from '../../components/QrCode'
import { LinkProps } from '../../components/Link'
import { IconProps } from '../../components/Icon'

import * as SC from './styled'

export type PersonalizationTemplateProps = {
  className?: string
  heart?: HeartProps
  heartForSharing?: {
    containerClassName: string
    heart: HeartProps
  }
  colorStep?: {
    isVisible: boolean
    title: string
    options: ToggleButtonProps[]
    button: ActionButtonProps
  }
  sparklesStep?: {
    isVisible: boolean
    title: string
    options: ToggleButtonProps[]
    button: ActionButtonProps
  }
  textStep?: {
    isVisible: boolean
    title: string
    options: ToggleButtonProps[]
    button: ActionButtonProps
  }
  shareStep?: {
    isVisible: boolean
    isShareLanding?: boolean
    mobileButton: ActionButtonProps
    mobileLinkButton?: ActionButtonProps
    mobileRestartButton: ActionButtonProps
    desktopButton?: ActionButtonProps & {
      iSuccess?: boolean
      isPending?: boolean
      successLabel?: string
    }
    socials?: {
      label: string
      items: Array<{
        link: LinkProps
        icon: IconProps
      }>
    }
    qrCode?: QrCodeProps
    qrCodeText?: string
    isSmallPodium?: boolean
    mobileTitle?: string
  }
}

const PersonalizationTemplate: FC<PersonalizationTemplateProps> = ({
  className,
  heart,
  heartForSharing,
  colorStep,
  sparklesStep,
  textStep,
  shareStep,
}) => {
  const $colorStepOptions = useRef<ElementRef<'div'>>(null)
  const $sparklesStepOptions = useRef<ElementRef<'div'>>(null)
  const $textStepOptions = useRef<ElementRef<'div'>>(null)

  return (
    <>
      <SC.Content className={className}>
        {heartForSharing && (
          <SC.HeartForSharingContainer>
            <SC.HeartForSharingContainer2 className={heartForSharing.containerClassName}>
              <SC.HeartForSharing {...heartForSharing.heart} isStaticSparkles />
            </SC.HeartForSharingContainer2>
          </SC.HeartForSharingContainer>
        )}
        <SwitchTransition mode={'out-in'}>
          <Transition
            key={
              colorStep?.isVisible
                ? 'color'
                : sparklesStep?.isVisible
                ? 'spakles'
                : textStep?.isVisible
                ? 'text'
                : shareStep?.isVisible
                ? 'share'
                : 'undefined'
            }
            addEndListener={(node, done) => {
              node.addEventListener('gsapComplete', done, false)
            }}
            onEnter={(node: HTMLElement) => {
              gsap.fromTo(
                node,
                {
                  opacity: 0,
                },
                {
                  duration: 0.4,
                  ease: 'none',
                  opacity: 1,
                }
              )
            }}
            onExit={(node: HTMLElement) => {
              gsap.to(node, {
                duration: 0.2,
                ease: 'none',
                opacity: 0,
                onComplete: () => {
                  node.dispatchEvent(new Event('gsapComplete'))
                },
              })
            }}
          >
            <SC.TitleMobile>
              {colorStep?.isVisible
                ? colorStep.title
                : sparklesStep?.isVisible
                ? sparklesStep.title
                : textStep?.isVisible
                ? textStep.title
                : shareStep?.isVisible
                ? shareStep.mobileTitle
                : ' '}
            </SC.TitleMobile>
          </Transition>
        </SwitchTransition>

        {shareStep?.isVisible && shareStep?.isShareLanding && (
          <SC.ShareLandingLogoIcon icon="logo" />
        )}

        <SC.HeartC {...heart} />

        <SwitchTransition mode={'out-in'}>
          <Transition
            key={
              colorStep?.isVisible
                ? 'color'
                : sparklesStep?.isVisible
                ? 'spakles'
                : textStep?.isVisible
                ? 'text'
                : shareStep?.isVisible
                ? 'share'
                : undefined
            }
            addEndListener={(node, done) => {
              node.addEventListener('gsapComplete', done, false)
            }}
            onEnter={(node: HTMLElement) => {
              gsap.fromTo(
                node,
                {
                  opacity: 0,
                },
                {
                  duration: 0.4,
                  ease: 'none',
                  opacity: 1,
                }
              )
            }}
            onExit={(node: HTMLElement) => {
              gsap.to(node, {
                duration: 0.2,
                ease: 'none',
                opacity: 0,
                onComplete: () => {
                  node.dispatchEvent(new Event('gsapComplete'))
                },
              })
            }}
          >
            <>
              {colorStep?.isVisible && (
                <SC.RightContainer>
                  <SC.TitleDesktop>{colorStep.title}</SC.TitleDesktop>
                  <SC.OptionsArrows>
                    <SC.OptionsArrowsIcon
                      icon="arrowLeft"
                      onClick={() => {
                        $colorStepOptions.current?.scrollTo({
                          left: 0,
                          behavior: 'smooth',
                        })
                      }}
                    />
                    <SC.OptionsArrowsIcon
                      icon="arrowRight"
                      onClick={() => {
                        $colorStepOptions.current?.scrollTo({
                          left: $colorStepOptions.current?.scrollWidth,
                          behavior: 'smooth',
                        })
                      }}
                    />
                  </SC.OptionsArrows>
                  <SC.Options ref={$colorStepOptions}>
                    {colorStep.options?.map((option) => (
                      <SC.Option key={option.label} {...option} />
                    ))}
                  </SC.Options>
                  <SC.Button {...colorStep.button} />
                </SC.RightContainer>
              )}

              {sparklesStep?.isVisible && (
                <SC.RightContainer>
                  <SC.TitleDesktop>{sparklesStep.title}</SC.TitleDesktop>
                  <SC.OptionsArrows>
                    <SC.OptionsArrowsIcon
                      icon="arrowLeft"
                      onClick={() => {
                        $sparklesStepOptions.current?.scrollTo({
                          left: 0,
                          behavior: 'smooth',
                        })
                      }}
                    />
                    <SC.OptionsArrowsIcon
                      icon="arrowRight"
                      onClick={() => {
                        $sparklesStepOptions.current?.scrollTo({
                          left: $sparklesStepOptions.current?.scrollWidth,
                          behavior: 'smooth',
                        })
                      }}
                    />
                  </SC.OptionsArrows>
                  <SC.Options ref={$sparklesStepOptions}>
                    {sparklesStep.options?.map((option) => (
                      <SC.Option key={option.label} {...option} />
                    ))}
                  </SC.Options>
                  <SC.Button {...sparklesStep.button} />
                </SC.RightContainer>
              )}

              {textStep?.isVisible && (
                <SC.RightContainer>
                  <SC.TitleDesktop>{textStep.title}</SC.TitleDesktop>
                  <SC.OptionsArrows>
                    <SC.OptionsArrowsIcon
                      icon="arrowLeft"
                      onClick={() => {
                        $textStepOptions.current?.scrollTo({
                          left: 0,
                          behavior: 'smooth',
                        })
                      }}
                    />
                    <SC.OptionsArrowsIcon
                      icon="arrowRight"
                      onClick={() => {
                        $textStepOptions.current?.scrollTo({
                          left: $textStepOptions.current?.scrollWidth,
                          behavior: 'smooth',
                        })
                      }}
                    />
                  </SC.OptionsArrows>
                  <SC.Options ref={$textStepOptions}>
                    {textStep.options?.map((option) => (
                      <SC.Option key={option.label} {...option} />
                    ))}
                  </SC.Options>
                  <SC.Button {...textStep.button} />
                </SC.RightContainer>
              )}

              {shareStep?.isVisible && (
                <SC.RightContainer $isReversed={shareStep.isSmallPodium} $isShare>
                  <SC.ShareButtonMobile
                    {...shareStep.mobileButton}
                    variant="primaryWithBackground"
                    isUppercase={false}
                  />
                  {shareStep.mobileLinkButton && (
                    <SC.ShareButtonMobile
                      {...shareStep.mobileLinkButton}
                      variant="primary"
                      isUppercase={false}
                    />
                  )}
                  <SC.RestartButtonMobile
                    {...shareStep.mobileRestartButton}
                    variant="primaryWithoutBackground"
                  />
                  <SC.Socials>
                    <SC.SocialsLabel>{shareStep.socials?.label}</SC.SocialsLabel>
                    {shareStep?.socials?.items?.map((item, index) => (
                      <SC.SocialsIconLink key={index} {...item.link}>
                        <SC.SocialsIcon {...item.icon} />
                      </SC.SocialsIconLink>
                    ))}
                  </SC.Socials>
                  {shareStep.desktopButton && (
                    <SwitchTransition>
                      <Transition
                        key={shareStep.desktopButton?.iSuccess ? 'success' : 'default'}
                        addEndListener={(node, done) => {
                          node?.addEventListener('gsapComplete', done, false)
                        }}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        onEnter={(node: HTMLElement) => {
                          gsap.fromTo(
                            node,
                            {
                              opacity: 0,
                            },
                            {
                              duration: 0.8,
                              ease: 'power3.out',
                              opacity: 1,
                            }
                          )
                        }}
                        onExit={(node: HTMLElement) => {
                          gsap.to(node, {
                            duration: 0.2,
                            ease: 'power1.out',
                            opacity: 0,
                            onComplete: () => {
                              node?.dispatchEvent(new Event('gsapComplete'))
                            },
                          })
                        }}
                      >
                        <SC.ShareButtonDesktop
                          $isSuccess={!!shareStep.desktopButton.iSuccess}
                          {...shareStep.desktopButton}
                          label={
                            shareStep.desktopButton.iSuccess
                              ? shareStep.desktopButton.successLabel
                              : shareStep.desktopButton.label
                          }
                          variant={
                            shareStep.desktopButton.iSuccess ? 'primaryWithBackground' : 'primary'
                          }
                          iconProps={
                            shareStep.desktopButton.iSuccess
                              ? {
                                  icon: 'check',
                                }
                              : undefined
                          }
                        />
                      </Transition>
                    </SwitchTransition>
                  )}
                  <SC.QRCodeContainer>
                    {shareStep.qrCode && <SC.QRCode {...shareStep.qrCode} />}
                    {shareStep.qrCodeText && <SC.QRCodeText>{shareStep.qrCodeText}</SC.QRCodeText>}
                  </SC.QRCodeContainer>
                </SC.RightContainer>
              )}
            </>
          </Transition>
        </SwitchTransition>
      </SC.Content>
    </>
  )
}

export default PersonalizationTemplate
