import React, { ElementRef, forwardRef, memo } from 'react'

import * as icons from './icons'
import * as SC from './styled'
import { IconProps } from './types'

export * from './types'

const Icon = forwardRef<ElementRef<'svg'>, IconProps>(function Icon(
  { className, icon, color, width, height, preserveAspectRatio, onClick },
  ref
) {
  const iconProps: any = icons[icon]
  const { viewBox, id } = iconProps

  return (
    <SC.Svg
      ref={ref}
      className={className}
      $color={color}
      viewBox={viewBox}
      onClick={onClick}
      {...(color && { color })}
      {...(width && { width })}
      {...(height && { height })}
      {...(preserveAspectRatio && { preserveAspectRatio })}
    >
      <use xlinkHref={`#${id}`} href={`#${id}`} />
    </SC.Svg>
  )
})
export default memo(Icon)
