import styled, { css } from 'styled-components'

import { theme } from '../../theme'

type CommonProps = {
  $checked?: boolean
  $disabled?: boolean
  $color?: string
  $textColor?: string
  $checkedTextColor?: string
  $backgroundColor?: string
  $checkedBackgroundColor?: string
  $checkedBorderColor?: string
}

export const Wrapper = styled.label<CommonProps>`
  border: 2px solid ${(props) => props.$backgroundColor};
  background-color: ${(props) => props.$backgroundColor};
  border-radius: 5rem;
  padding: 0.8rem 1.8rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border 0.3s ease;

  ${({ $checked, $checkedBackgroundColor, $checkedBorderColor }) =>
    $checked &&
    css`
      background-color: ${$checkedBackgroundColor};
      border: 2px solid ${$checkedBorderColor};
    `};

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}

  ${theme.breakpoints.up('tablet')} {
    padding: 1rem 2rem;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    padding: 1.2rem 2.4rem;
  }
`

export const Checkbox = styled.input`
  display: none;
`

export const Label = styled.span<CommonProps>`
  ${({ theme }) => theme.textStyles.titleH6};
  color: ${({ $textColor }) => $textColor};
  transition: color 0.3s ease;

  ${({ $checked, $checkedTextColor }) =>
    $checked &&
    css`
      color: ${$checkedTextColor};
    `};

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.4;
    `}

  ${theme.breakpoints.up('tablet')} {
    ${({ theme }) => theme.textStyles.titleH5};
    font-size: 1.7rem;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    ${({ theme }) => theme.textStyles.titleH5};
  }
`
