import { createSlice, PayloadAction } from '@reduxjs/toolkit'

//
// Initial state
//

type TrackingState = Record<string, any>

const initialState: TrackingState = {}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    pushEvent: (
      _state: any,
      _action: PayloadAction<{ event: string; data?: Record<string, any> }>
    ) => undefined,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

// const root = (state: RootState) => state[slice.name]

export const selectors = {}
