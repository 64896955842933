import styled from 'styled-components'

import { theme } from '../../theme'
import Heart from '../../components/Heart'

export const Content = styled.div`
  flex-grow: 1;
  min-height: 100%;
  padding: 12rem 2rem 2rem 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  ${theme.breakpoints.up('tablet')} {
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    padding: 130px 40px 50px 40px;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    padding: 155px 40px 60px 40px;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

export const ItemTitle = styled.p`
  ${(props) => props.theme.textStyles.titleH5}
  color: ${(props) => props.theme.colors.gold};
  text-align: center;

  ${theme.breakpoints.up('tablet')} {
    ${(props) => props.theme.textStyles.titleH3}
  }
`

export const ItemCta = styled.p`
  margin-top: 1.2rem;
  ${(props) => props.theme.textStyles.titleH6}
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.gold};
  border-bottom: 1.5px solid ${(props) => props.theme.colors.gold};
  padding-bottom: 0.4rem;
  ${theme.breakpoints.up('tablet')} {
    ${(props) => props.theme.textStyles.titleH5}
  }
`

export const ItemHeart = styled(Heart)`
  position: relative;
  width: 220px;
  margin-top: 1.2rem;

  ${theme.breakpoints.up('tablet')} {
    width: 340px;
  }
`

export const TextTitle = styled.p`
  ${(props) => props.theme.textStyles.titleH5}
  color: ${(props) => props.theme.colors.gold};
  margin-top: auto;
  padding-top: 2rem;

  ${theme.breakpoints.up('tablet')} {
    ${(props) => props.theme.textStyles.titleH3}
  }
`

export const Text = styled.p`
  ${(props) => props.theme.textStyles.textSmall}
  color: ${(props) => props.theme.colors.gold};
  margin-top: 2.2rem;

  ${theme.breakpoints.up('tablet')} {
    ${(props) => props.theme.textStyles.text};
    margin-bottom: 60px;
    max-width: 42rem;
  }
`
