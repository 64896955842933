const colors = {
  pureBlack: '#000',
  pureWhite: '#ffffff',
  red: '#FE576B',
  error: '#FE576B',
  gold: '#9F834A',
  beige: '#FFF7E6',
  beige75: 'rgba(255,247,230,0.75)',
  loveHope: '#CE9F8A',
  loveRespect: '#B57461',
  loveGenerosity: '#98634B',
  loveGratitude: '#D07066',
  loveAffection: '#CD828D',
  loveHappiness: '#C35B36',
  lovePride: '#A64934',
  loveDevotion: '#C33323',
  loveAudacity: '#A32420',
  citrus: '#F9E293',
  orange: '#F5AF95',
} as const

export default colors
