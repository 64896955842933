export enum Step {
  landing = 'landing',
  selection = 'selection',
  iconicShare = 'iconicShare',
  personalizationColor = 'personalizationColor',
  personalizationSparkles = 'personalizationSparkles',
  personalizationText = 'personalizationText',
  personalizationShare = 'personalizationShare',
  wolSmallPodium = 'wolSmallPodium',
}

export enum Color {
  iconic = '0',
  speranza = '1',
  rispetto = '2',
  generosita = '3',
  gratitudine = '4',
  affetto = '5',
  felicita = '6',
  orgoglio = '7',
  devozione = '8',
  audacia = '9',
}

export enum Sparkles {
  citrus = '0',
  orange = '1',
  vanilla = '2',
}

export enum Declaration {
  lovedOne = '0',
  family = '1',
  bestFriend = '2',
  lifePartner = '3',
  myself = '4',
  city = '5',
  origins = '6',
  traditions = '7',
  future = '8',
  moment = '9',
  iconic = '10',
}

export type HeartConfig = {
  color: Color
  declaration: Declaration
  sparkles?: Sparkles
}

export enum WolType {
  'smallPodium' = 'smallPodium',
  'largePodium' = 'largePodium',
}

export enum ReactNativeMessages {
  sendHeartToWol = 'sendHeartToWol',
  heartSpawn = 'heartSpawn',
  hasExternalDisplay = 'hasExternalDisplay',
}
