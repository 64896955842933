import styled from 'styled-components'

import Link from '../Link'

import { ReactComponent as HeartSvg } from './heart.svg'

export const Container = styled(Link)<{ $color?: string }>`
  position: relative;
  width: 100%;
  display: block;
  color: ${(props) => props.$color ?? 'inherit'};
`

export const SparklesPhaser = styled.div<{ $visible: boolean }>`
  position: absolute;
  width: 130%;
  height: 130%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  ${(props) => (props.$visible ? 'block' : 'none')};
  pointer-events: none;

  canvas {
    width: 100%;
  }
`

export const HeartIcon = styled(HeartSvg)`
  width: 100%;
`

export const TextContainer = styled.div`
  position: absolute;
  top: 33%;
  left: calc((100% - 60%) / 2);
  width: 60%;
  height: 49%;
`

export const ShapeLeft = styled.div`
  width: 46%;
  height: 100%;
  shape-outside: polygon(
    0% 0%,
    0% 100%,
    100% 100%,
    100% 90%,
    65.35% 72.26%,
    20% 40%,
    10% 20%,
    20% 0%
  );
  float: left;
`

export const ShapeRight = styled.div`
  width: 46%;
  height: 100%;
  shape-outside: polygon(
    100% 0px,
    100% 100%,
    0px 100%,
    0 90%,
    46% 67.62%,
    80% 40%,
    90% 20%,
    80% 0px
  );
  float: right;
`

export const Text = styled.p<{ $fontSize?: string }>`
  position: relative;
  padding-top: 20%;
  text-align: center;
  ${({ theme }) => theme.textStyles.titleH4};
  font-size: ${(props) => props.$fontSize || 0};
  text-transform: uppercase;
  opacity: ${(props) => (props.$fontSize ? 1 : 0)};

  span {
    font-size: 1em;

    // custom font scalling
    html[lang='ar'] & {
      font-size: 1.6em;
    }
  }
`

export const SparklesLeft = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 54%;
  transform: translate(-36%, -44%);
`

export const SparklesRight = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 54%;
  transform: translate(40%, -5%);
`
