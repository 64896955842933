import React, { FC, useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import * as SC from './styled'

export type HeartCounterProps = {
  className?: string
  number?: string
  text?: string
}

const HeartCounter: FC<HeartCounterProps> = (props) => {
  const { className, number = '0', text } = props

  const $heart = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.5 })

    tl.to($heart.current!, {
      scale: 1.05,
      duration: 6 / 15,
      ease: 'power1.inOut',
    })
      .to($heart.current!, {
        scale: 1.03,
        duration: 2 / 15,
        ease: 'none',
      })
      .to($heart.current!, {
        scale: 1.04,
        duration: 2 / 15,
        ease: 'none',
      })
      .to($heart.current!, {
        scale: 1.05,
        duration: 2 / 15,
        ease: 'power1.inOut',
      })
      .to($heart.current!, {
        scale: 1,
        duration: 3 / 15,
        ease: 'none',
      })
  }, [])

  return (
    <SC.Container className={className}>
      <SC.Top>
        <SC.HeartIcon ref={$heart} icon="heartWithLogo" />
        <SC.Number>{number}</SC.Number>
      </SC.Top>
      <SC.Text>{text}</SC.Text>
    </SC.Container>
  )
}

export default HeartCounter
