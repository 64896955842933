import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { RouteProps, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { actions, selectors } from '../../redux'
import { Color, Declaration, ReactNativeMessages, Sparkles, WolType } from '../../types/global'
import WolTemplate from '../../templates/Wol'
import { useListenReactNativeMessage } from '../../hooks/useListenReactNativeMessage'

const WolPage: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const [searchParams] = useSearchParams()

  const isDebug = searchParams.has('debug')

  // refs

  const $debugColorSelect = useRef<HTMLSelectElement>(null)
  const $debugDeclarationSelect = useRef<HTMLSelectElement>(null)
  const $debugSparklesSelect = useRef<HTMLSelectElement>(null)

  // selectors

  const heartsQueue = useSelector(selectors.app.heartsQueue)

  const declarationLabels = useMemo(
    () =>
      Object.values(Declaration).reduce(
        (prev, currentValue) => ({
          ...prev,
          [currentValue]: t('heart.text', {
            preposition: t(`enum.declaration.preposition.${currentValue}` as any),
            subject: t(`enum.declaration.${currentValue}` as any),
          }),
        }),
        {} as { [key in Declaration]: string }
      ),
    [t]
  )

  // effects

  // if debug -> remove all hearts in queue
  useEffect(() => {
    if (isDebug) {
      dispatch(actions.app.setHeartsQueue([]))
    }
  }, [dispatch, isDebug])

  // listen message from react-native app
  useListenReactNativeMessage(
    useCallback(
      (message) => {
        if (message.id === ReactNativeMessages.sendHeartToWol) {
          dispatch(
            actions.app.addHeartToQueue({
              color: message.payload.color as Color,
              declaration: message.payload.declaration as Declaration,
              sparkles: message.payload.sparkles as Sparkles,
            })
          )
        }
      },
      [dispatch]
    )
  )

  // return

  return (
    <WolTemplate
      wolPhaser={{
        wolType: WolType.largePodium,
        isActive: true,
        isDebug: isDebug,
        currentLocale: i18n.language,
        declarationLabels: declarationLabels,
        heartsQueue: heartsQueue,
        dispatch,
        onHeartSpawn: () => {
          dispatch(actions.app.onHeartSpawn())
        },
      }}
      heartCounter={{
        text: t('layout.heartCount'),
        number: String(heartsQueue.length),
      }}
      debug={
        isDebug
          ? {
              colorSelect: {
                label: 'Color',
                select: {
                  ref: $debugColorSelect,
                },
                options: Object.values(Color).map((color) => ({
                  label: t(`enum.color.${color}` as any),
                  value: color,
                })),
              },
              declarationSelect: {
                label: 'Declaration',
                select: {
                  ref: $debugDeclarationSelect,
                },
                options: Object.values(Declaration).map((declaration) => ({
                  label: t(`enum.declaration.${declaration}` as any),
                  value: declaration,
                })),
              },
              sparklesSelect: {
                label: 'Sparkles',
                select: {
                  ref: $debugSparklesSelect,
                },
                options: Object.values(Sparkles).map((sparkle) => ({
                  label: t(`enum.sparkles.${sparkle}` as any),
                  value: sparkle,
                })),
              },
              button: {
                label: 'Add heart',
                onClick: () => {
                  dispatch(
                    actions.app.addHeartToQueue({
                      color: $debugColorSelect.current?.value as Color,
                      declaration: $debugDeclarationSelect.current?.value as Declaration,
                      sparkles: $debugSparklesSelect.current?.value as Sparkles,
                    })
                  )
                },
              },
            }
          : undefined
      }
    />
  )
}

export default WolPage
