import React, { FC, useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import { theme } from '../../theme'
import { HeartProps } from '../../components/Heart'

import * as SC from './styled'

export type LandingTemplateProps = {
  className?: string
  title?: string
  heart?: HeartProps
  textTitle?: string
  text?: string
}

const LandingTemplate: FC<LandingTemplateProps> = ({ title, heart, textTitle, text }) => {
  const $heart = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.5 })

    tl.to($heart.current!, {
      scale: 1.05,
      duration: 6 / 15,
      ease: 'power1.inOut',
    })
      .to($heart.current!, {
        scale: 1.03,
        duration: 2 / 15,
        ease: 'none',
      })
      .to($heart.current!, {
        scale: 1.04,
        duration: 2 / 15,
        ease: 'none',
      })
      .to($heart.current!, {
        scale: 1.05,
        duration: 2 / 15,
        ease: 'power1.inOut',
      })
      .to($heart.current!, {
        scale: 1,
        duration: 3 / 15,
        ease: 'none',
      })
  }, [])

  return (
    <SC.Content>
      <SC.Title>{title}</SC.Title>
      <SC.HeartC color={theme.colors.loveGratitude} {...heart} ref={$heart} />
      <SC.TextTitle>{textTitle}</SC.TextTitle>
      <SC.Text>{text}</SC.Text>
      <SC.HiddenQRCode url={'/'} />
    </SC.Content>
  )
}

export default LandingTemplate
