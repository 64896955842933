import { all, call, put, takeLatest, select, delay } from 'redux-saga/effects'

import { actions, selectors } from '..'

export default class InactivitySagas {
  static *initTimer() {
    yield put(actions.inactivity.setTimer({ timer: 60 }))
  }

  static *startTimer() {
    yield put(actions.inactivity.setIsStarted({ isStarted: true }))
    let isStarted: ReturnType<typeof selectors.inactivity.isStarted> = yield select(
      selectors.inactivity.isStarted
    )

    while (isStarted) {
      yield put(actions.inactivity.decrementTimer())
      yield delay(1000)
      isStarted = yield select(selectors.inactivity.isStarted)
    }
  }

  static *stopTimer() {
    yield put(actions.inactivity.setIsStarted({ isStarted: false }))
    // yield call(InactivitySagas.initTimer)
  }

  static *resetTimer() {
    // yield put(actions.inactivity.setIsStarted({ isStarted: true }))
    yield call(InactivitySagas.initTimer)
  }

  static *listeners() {
    yield all([
      takeLatest(actions.inactivity.startTimer, this.startTimer),
      takeLatest(actions.inactivity.resetTimer, this.resetTimer),
      takeLatest(actions.inactivity.stopTimer, this.stopTimer),
    ])
  }
}
