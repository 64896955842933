import React, { FC } from 'react'

import { WolPhaserProps } from '../../components/WolPhaser'
import { HeartCounterProps } from '../../components/HeartCounter'

import * as SC from './styled'

export type WolTemplateProps = {
  className?: string
  wolPhaser: WolPhaserProps
  heartCounter?: HeartCounterProps
  debug?: {
    colorSelect: {
      label: string
      select: React.ComponentPropsWithRef<'select'>
      options: {
        label: string
        value: string
      }[]
    }
    declarationSelect: {
      label: string
      select: React.ComponentPropsWithRef<'select'>
      options: {
        label: string
        value: string
      }[]
    }
    sparklesSelect: {
      label: string
      select: React.ComponentPropsWithRef<'select'>
      options: {
        label: string
        value: string
      }[]
    }
    button: {
      label: string
      onClick: () => void
    }
  }
}

const WolTemplate: FC<WolTemplateProps> = ({ className, wolPhaser, heartCounter, debug }) => {
  return (
    <SC.Content className={className}>
      <SC.Wol {...wolPhaser} />
      <SC.LogoIcon icon="logo" />
      {heartCounter && <SC.HeartCount {...heartCounter} />}
      {debug && (
        <SC.DebugContainer>
          <label htmlFor="color">
            <p>{debug.colorSelect.label}</p>
            <select id={'color'} {...debug.colorSelect.select}>
              {debug.colorSelect.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
          <label htmlFor="declaration">
            <p>{debug.declarationSelect.label}</p>
            <select id={'declaration'} {...debug.declarationSelect.select}>
              {debug.declarationSelect.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
          <label htmlFor="sparkles">
            <p>{debug.sparklesSelect.label}</p>
            <select id="sparkles" {...debug.sparklesSelect.select}>
              {debug.sparklesSelect.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
          <button onClick={debug.button.onClick}>{debug.button.label}</button>
        </SC.DebugContainer>
      )}
    </SC.Content>
  )
}

export default WolTemplate
