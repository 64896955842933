import styled from 'styled-components'

import WolPhaser from '../../components/WolPhaser'
import { theme } from '../../theme'

export const Content = styled.div<{ $isClickable: boolean; $isInitiallyTransparent: boolean }>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: ${(props) => (props.$isClickable ? 'auto' : 'none')};
  opacity: ${(props) => (props.$isInitiallyTransparent ? 0 : 1)};
`

export const Wol = styled(WolPhaser)<{ $width?: number }>`
  align-self: stretch;
  width: ${(p) => p.$width ?? 'auto'};
`

export const Title = styled.p`
  color: ${(props) => props.theme.colors.gold};
  ${(props) => props.theme.textStyles.titleH2};
  font-size: 3rem;
  text-transform: uppercase;
  margin-right: 40px;
  text-align: right;
  white-space: pre-line;

  ${theme.breakpoints.up('tabletLarge')} {
    margin-right: 50px;
    ${(props) => props.theme.textStyles.titleH2};
  }
`
