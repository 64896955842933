import * as apiRedux from './api/redux'
import * as appRedux from './app/redux'
import * as authRedux from './auth/redux'
import * as exampleRedux from './example/redux'
import * as inactivityRedux from './inactivity/redux'
import * as trackingRedux from './tracking/redux'

export const actions = {
  app: appRedux.actions,
  api: apiRedux.actions,
  auth: authRedux.actions,
  example: exampleRedux.actions,
  inactivity: inactivityRedux.actions,
  tracking: trackingRedux.actions,
}

export const selectors = {
  app: appRedux.selectors,
  api: apiRedux.selectors,
  auth: authRedux.selectors,
  example: exampleRedux.selectors,
  inactivity: inactivityRedux.selectors,
  tracking: trackingRedux.selectors,
}

export const reducers = {
  app: appRedux.reducer,
  api: apiRedux.reducer,
  auth: authRedux.reducer,
  example: exampleRedux.reducer,
  inactivity: inactivityRedux.reducer,
  tracking: trackingRedux.reducer,
}
