import * as Phaser from 'phaser'
import { Dispatch } from 'redux'

import { selectors } from '../redux'
import { WolType } from '../types/global'

import MainScene from './scenes/MainScene'

type GameData = {
  dispatch?: Dispatch
  pixelRatio: number
  heartsQueue: ReturnType<typeof selectors.app.heartsQueue>
  currentLocale: string
  wolType: WolType
  onHeartSpawn: () => void
}

export class Game extends Phaser.Game {
  mainScene: MainScene

  dispatch?: Dispatch
  pixelRatio: number
  heartsQueue: GameData['heartsQueue']
  currentLocale: string
  wolType: WolType
  onHeartSpawn: () => void

  constructor(config: Phaser.Types.Core.GameConfig, gameData: GameData) {
    super(config)
    console.log('Game: constructor')

    const { dispatch, pixelRatio, heartsQueue, currentLocale, wolType, onHeartSpawn } = gameData

    this.dispatch = dispatch
    this.pixelRatio = pixelRatio
    this.heartsQueue = heartsQueue
    this.currentLocale = currentLocale
    this.wolType = wolType
    this.onHeartSpawn = onHeartSpawn

    // create scene
    this.mainScene = new MainScene({ game: this })
    this.scene.add('main-scene', this.mainScene, false)
  }

  // SETTERS

  setDispatch(dispatch?: Dispatch) {
    this.dispatch = dispatch
  }

  setWolType(wolType: WolType) {
    this.wolType = wolType
    this.updateWolConfiguration()
  }

  // FUNCTION

  updateWolConfiguration() {
    this.mainScene.updateWolConfiguration()
  }

  addTextTexture(key: string, canvas: HTMLCanvasElement) {
    console.log('Game: addTextTexture', key)
    this.mainScene.textures.addCanvas(key, canvas)
  }

  startMainScene() {
    this.scene.start('main-scene')
  }
}
