import styled, { css } from 'styled-components'

import { theme } from '../../theme'
import Heart from '../../components/Heart'
import ToggleButton from '../../components/ToggleButton'
import ActionButton from '../../components/ActionButton'
import QrCode from '../../components/QrCode'
import Icon from '../../components/Icon'
import Link from '../../components/Link'

export const Content = styled.div`
  flex-grow: 1;
  min-height: 100%;
  padding: 12rem 0 6rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  ${theme.breakpoints.up('tablet')} {
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    padding: 110px 40px 80px 40px;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    gap: 60px;
    padding: 120px 60px;
  }
`

export const ShareLandingLogoIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  color: ${(props) => props.theme.colors.gold};
  width: 140px;
  ${theme.breakpoints.up('tablet')} {
    top: 40px;
    width: 250px;
  }
`

export const RightContainer = styled.div<{ $isReversed?: boolean; $isShare?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.4rem;
  align-self: stretch;
  gap: ${(props) => (props.$isShare ? '1.8rem' : '3.6rem')};

  ${theme.breakpoints.up('tablet')} {
    align-self: center;
    gap: ${(props) => (props.$isShare ? '3.6rem' : '4rem')};
    flex: 1;
    margin-top: 0;
    ${(props) =>
      props.$isReversed &&
      css`
        flex-direction: column-reverse;
      `}
  }

  ${theme.breakpoints.up('tabletLarge')} {
    gap: ${(props) => (props.$isShare ? '3.6rem' : '6.4rem')};
  }
`

export const TitleMobile = styled.p`
  ${(props) => props.theme.textStyles.titleH4}
  color: ${(props) => props.theme.colors.gold};
  text-transform: uppercase;
  margin-left: 2rem;
  margin-right: 2rem;

  // keep line height for empty text
  &:empty:before {
    content: '\\200b'; // unicode zero width space character
  }

  ${theme.breakpoints.up('tablet')} {
    display: none;
  }
`

export const TitleDesktop = styled(TitleMobile)`
  display: none;

  ${theme.breakpoints.up('tablet')} {
    display: initial;
    text-align: center;
    ${(props) => props.theme.textStyles.titleH2};
    font-size: 3rem;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    ${(props) => props.theme.textStyles.titleH2}
  }
`

export const HeartC = styled(Heart)`
  position: relative;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2.4rem;
  width: calc(95% - 4rem);
  max-width: 330px;
  flex-shrink: 0;
  pointer-events: none;

  ${theme.breakpoints.up('tablet')} {
    margin-left: initial;
    margin-right: initial;
    margin-top: 0;
    max-width: 375px;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    max-width: 525px;
  }
`

export const HeartForSharingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
  opacity: 0;
`

export const HeartForSharingContainer2 = styled.div`
  width: 500px;
  padding: 4rem;
`

export const HeartForSharing = styled(Heart)``

export const OptionsArrows = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
  color: ${(props) => props.theme.colors.gold};
  margin-bottom: calc(-3.6rem + 1rem);

  ${theme.breakpoints.up('tablet')} {
    display: none;
  }
`

export const OptionsArrowsIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`

export const Options = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 0.8rem;
  width: 100%;
  padding: 0 2rem;

  &::-webkit-scrollbar {
    display: none;
  }

  ${theme.breakpoints.up('tablet')} {
    column-gap: 1.2rem;
    row-gap: 2rem;
    overflow-x: initial;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    column-gap: 1.8rem;
    row-gap: 2.4rem;
  }
`

export const Option = styled(ToggleButton)`
  flex-shrink: 0;
  ${theme.breakpoints.up('tablet')} {
    max-width: 42rem;
  }
`

export const Button = styled(ActionButton)``

export const ShareButtonMobile = styled(ActionButton)`
  align-self: stretch;
  margin-left: 2rem;
  margin-right: 2rem;

  ${theme.breakpoints.up('tablet')} {
    display: none;
  }
`

export const ShareButtonDesktop = styled(ActionButton)<{ $isSuccess: boolean }>`
  display: none;

  ${theme.breakpoints.up('tablet')} {
    display: inline-flex;
    padding: ${(props) => (props.$isSuccess ? '1.3rem 6rem' : '1.3rem 9rem')};
    svg {
      margin-right: 1.5rem;
    }

    pointer-events: ${(props) => (props.$isSuccess ? 'none' : 'auto')};
  }

  ${theme.breakpoints.up('tabletLarge')} {
    padding: ${(props) => (props.$isSuccess ? '1.6rem 6rem' : '1.6rem 9rem')};
    svg {
      margin-right: 1.5rem;
    }
  }
`

export const RestartButtonMobile = styled(ActionButton)`
  padding: 1.2rem 3.6rem;
  ${theme.breakpoints.up('tablet')} {
    display: none;
  }
`

export const QRCodeContainer = styled.div`
  display: none;

  ${theme.breakpoints.up('tablet')} {
    display: flex;
    align-items: center;
    gap: 36px;
  }
`

export const QRCode = styled(QrCode)`
  width: 150px;
`

export const QRCodeText = styled.p`
  ${(props) => props.theme.textStyles.text};
  color: ${(props) => props.theme.colors.gold};
  max-width: 28rem;
`

export const Socials = styled.div`
  margin-top: auto;
  padding-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  ${theme.breakpoints.up('tablet')} {
    display: none;
  }
`
export const SocialsLabel = styled.p`
  ${(props) => props.theme.textStyles.text};
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.gold};
`

export const SocialsIconLink = styled(Link)`
  color: ${(props) => props.theme.colors.gold};
`

export const SocialsIcon = styled(Icon)`
  width: 2.4rem;
`
