import styled from 'styled-components'

import ActionButton from '../../components/ActionButton'
import { theme } from '../../theme'
import HeartCounter from '../../components/HeartCounter'
import Icon from '../../components/Icon'
import FormFieldSelect from '../../components/form/fields/FormFieldSelect'

export const Container = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.beige};
`

export const LogoIcon = styled(Icon)`
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  color: ${(props) => props.theme.colors.gold};
  width: 140px;

  ${theme.breakpoints.up('tablet')} {
    top: 30px;
    width: 200px;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    top: 40px;
    width: 250px;
  }
`

export const BackButton = styled(ActionButton)`
  position: absolute;
  left: 2rem;
  top: 1.2rem;

  ${theme.breakpoints.up('tablet')} {
    left: 4rem;
    top: 4rem;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    top: 5rem;
  }
`

export const RestartButton = styled(ActionButton)`
  position: absolute;
  left: 2rem;
  bottom: 1.2rem;

  ${theme.breakpoints.up('tablet')} {
    left: 4rem;
    bottom: 4rem;
  }

  ${theme.breakpoints.up('tablet')} {
    bottom: 5rem;
  }
`

export const HeartCount = styled(HeartCounter)`
  display: none;
  ${theme.breakpoints.up('tablet')} {
    position: absolute;
    display: inline-flex;
    right: 4rem;
    bottom: 3rem;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    bottom: 4rem;
  }
`

export const LocalesSelector = styled(FormFieldSelect)`
  position: absolute;
  color: ${(props) => props.theme.colors.gold};
  z-index: 2;
  right: 1.5rem;
  top: 0.3rem;

  select {
    text-align: right;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.colors.gold};
    padding-right: 3rem;
    ${({ theme }) => theme.textStyles.titleH6};
  }

  ${theme.breakpoints.up('tablet')} {
    right: 4rem;
    top: 3rem;

    select {
      padding-right: 3.5rem;
      ${({ theme }) => theme.textStyles.titleH5}
    }
  }

  ${theme.breakpoints.up('tabletLarge')} {
    right: 4rem;
    top: 4rem;
  }
`
