/* eslint-disable import/no-named-as-default-member */
import Phaser from 'phaser'

import { Sparkles } from '../../types/global'

export class SparklesScene extends Phaser.Scene {
  sparklesSprite?: Phaser.GameObjects.Sprite

  sparkles: Sparkles

  constructor({ sparkles }: { sparkles: Sparkles }) {
    super()

    this.sparkles = sparkles

    console.log('SparklesScene: constructor')
  }

  preload() {
    // load sparkles
    this.load.multiatlas('sparkles', 'static/animations/sparkles.json', 'static/animations')
  }

  create() {
    // create sparkles animations
    Object.values(Sparkles).forEach((sparkles) => {
      this.anims.create({
        key: `sparkles-${sparkles}`,
        frames: this.anims.generateFrameNames('sparkles', {
          prefix: `sparkles-${sparkles}/`,
          suffix: '.png',
          start: 0,
          end: 24,
          zeroPad: 2,
        }),
        frameRate: 15,
      })
    })

    this.sparklesSprite = this.add.sprite(
      this.game.canvas.width / 2,
      this.game.canvas.height / 2,
      'sparkles',
      `sparkles-${this.sparkles}/00.png`
    )

    this.sparklesSprite.displayWidth = this.game.canvas.width
    this.sparklesSprite.scaleY = this.sparklesSprite.scaleX
  }

  setSparkles(sparkles: Sparkles) {
    this.sparkles = sparkles
  }

  playAnim() {
    console.log('playAnim')
    this.sparklesSprite?.anims.play(`sparkles-${this.sparkles}`)
  }
}
