import * as Phaser from 'phaser'

import { theme } from '../../theme'

export const getGameConfig = ({
  width,
  height,
  parent,
  scene,
  pixelRatio = window.devicePixelRatio,
  debug = false,
}: {
  width: number
  height: number
  parent: Phaser.Types.Core.GameConfig['parent']
  scene?: Phaser.Types.Core.GameConfig['scene']
  pixelRatio?: number
  debug?: boolean
}): Phaser.Types.Core.GameConfig => ({
  type: Phaser.AUTO,
  width: width * pixelRatio,
  height: height * pixelRatio,
  zoom: 1 / pixelRatio,
  // note : resolution doesn't work, see : https://github.com/photonstorm/phaser/issues/4417
  // resolution: pixelRatio
  parent: parent,
  // transparent: true,
  // to fix html2canvas warning
  preserveDrawingBuffer: true,
  backgroundColor: theme.colors.beige,
  physics: {
    default: 'matter',
    matter: {
      enableSleeping: true,
      debug: debug
        ? {
            showBody: true,
            showStaticBody: true,
            showInternalEdges: true,
            showVelocity: true,
            showCollisions: true,
            lineThickness: 2,
            showPositions: true,
            positionSize: 6,
          }
        : false,
      setBounds: {
        x: 0,
        left: true,
        right: true,
        top: false,
        bottom: false,
        width: width * pixelRatio,
        height: height * pixelRatio,
        thickness: 30 * pixelRatio,
      },
      // 'plugins.wrap': true,
    },
  },
  plugins: {},
  scene,
})
