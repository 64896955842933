import { Color, Declaration, Sparkles } from '../types/global'

const randomProperty = (obj: Record<string, any>) => {
  const keys = Object.keys(obj)
  return obj[keys[(keys.length * Math.random()) << 0]]
}

export const getRandomHeartConfig = () => {
  const color = randomProperty(Object.values(Color)) as Color
  const sparkles = randomProperty(Object.values(Sparkles)) as Sparkles
  const declaration = randomProperty(Object.values(Declaration)) as Declaration

  return {
    color,
    declaration,
    sparkles,
  }
}

export const getRandomHeartsConfig = (number = 1) => {
  return new Array(number).fill(null).map(() => getRandomHeartConfig())
}
