import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import NotFoundTemplate, { NotFoundTemplateProps } from '../templates/NotFound'
import { router, routesPath } from '../router'

function Page404() {
  const { t } = useTranslation()
  const notFoundProps: NotFoundTemplateProps = useMemo(
    () => ({
      title: t('notFound_title'),
      cta: {
        text: t('notFound_cta'),
        link: {
          href: router(routesPath.root),
        },
      },
    }),
    [t]
  )

  return <NotFoundTemplate {...notFoundProps} />
}

export default Page404
