import * as Phaser from 'phaser'

import type MainScene from '../scenes/MainScene'
import { Color, Declaration, Sparkles, WolType } from '../../types/global'
import { colorsHex } from '../../helpers/ColorsHelpers'

const getConfigurations = () => {
  const smallPodiumMaxFrequency = 0.3
  const largePodiumMaxFrequency = 0.17

  const randomIntFromInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  return {
    [WolType.smallPodium]: {
      velocityXAmplitude: randomIntFromInterval(700, 2000) / 1000,
      velocityXFrenquency: randomIntFromInterval(100, smallPodiumMaxFrequency * 1000) / 1000,
      velocityXTimeOffset: randomIntFromInterval(0, (1 / smallPodiumMaxFrequency) * 1000) / 1000,
    },
    [WolType.largePodium]: {
      velocityXAmplitude: randomIntFromInterval(1500, 2700) / 1000,
      velocityXFrenquency: randomIntFromInterval(80, largePodiumMaxFrequency * 1000) / 1000,
      velocityXTimeOffset: randomIntFromInterval(0, (1 / largePodiumMaxFrequency) * 1000) / 1000,
    },
  }
}

export default class Heart {
  scene: MainScene
  initialWidth: number
  x: number
  y: number
  color: Color
  options?: Phaser.Types.Physics.Matter.MatterBodyConfig
  declarationId: Declaration
  sparkles?: Sparkles

  matterSprite?: Phaser.Physics.Matter.Sprite
  sparklesContainer?: Phaser.GameObjects.Container
  sparklesSprite?: Phaser.GameObjects.Sprite
  uuid: ReturnType<typeof Phaser.Utils.String.UUID>
  velocityXAmplitude: number
  velocityXFrenquency: number
  velocityXTimeOffset: number

  constructor({
    scene,
    x = 0,
    y = 0,
    options,
    initialWidth = 100,
    color = Color.iconic,
    declarationId = Declaration.lovedOne,
    sparkles,
  }: {
    scene: MainScene
    x?: number
    y?: number
    texture?: string
    options?: Phaser.Types.Physics.Matter.MatterBodyConfig
    initialWidth?: number
    color?: Color
    declarationId?: Declaration
    sparkles?: Sparkles
  }) {
    this.uuid = Phaser.Utils.String.UUID()
    this.x = x
    this.y = y
    this.scene = scene
    this.initialWidth = initialWidth
    this.color = color
    this.options = options
    this.declarationId = declarationId
    this.sparkles = sparkles

    // random velocity params
    this.velocityXAmplitude = getConfigurations()[this.scene.game.wolType].velocityXAmplitude
    this.velocityXFrenquency = getConfigurations()[this.scene.game.wolType].velocityXFrenquency
    this.velocityXTimeOffset = getConfigurations()[this.scene.game.wolType].velocityXTimeOffset

    this.init()
  }

  // FUNCTIONS

  init() {
    const textureKey = this.getTexture()

    // create sprite
    this.matterSprite = this.scene.matter.add.sprite(
      this.x,
      this.y,
      textureKey,
      undefined,
      this.options
    )

    // set isHeart data
    this.matterSprite.setData('isHeart', true)

    // set uuid data
    this.matterSprite.setData('uuid', this.uuid)

    // set size
    this.matterSprite.displayWidth = this.initialWidth * this.scene.game.pixelRatio
    this.matterSprite.scaleY = this.matterSprite.scaleX

    // set color
    this.matterSprite.setTint(Phaser.Display.Color.HexStringToColor(colorsHex[this.color]).color)

    // sparkles
    this.initSparkles()
  }

  initSparkles() {
    if (!this.sparkles) {
      return
    }
    // container
    this.sparklesContainer = this.scene.add.container(this.x, this.y, [])
    this.sparklesContainer.setSize(this.matterSprite!.width, this.matterSprite!.height)
    this.sparklesContainer.scaleX = this.matterSprite!.scaleX
    this.sparklesContainer.scaleY = this.matterSprite!.scaleY

    // sprite
    this.sparklesSprite = this.scene.add.sprite(
      0,
      0,
      'sparkles',
      `sparkles-${this.sparkles}/00.png`
    )
    this.sparklesSprite.displayWidth = this.sparklesContainer.width * 1.5
    this.sparklesSprite.scaleY = this.sparklesSprite.scaleX
    this.sparklesContainer.add(this.sparklesSprite)
  }

  getTexture() {
    const textureKey = `heart-${this.scene.game.currentLocale}-${this.declarationId}`

    const isTexturealredyExist = this.scene.textures.exists(textureKey)

    // if texture does not exist -> create it
    if (!isTexturealredyExist) {
      // create heart & text sprites
      const heartSprite = new Phaser.GameObjects.Sprite(this.scene, 0, 0, 'heart', undefined)
      const textSprite = new Phaser.GameObjects.Sprite(
        this.scene,
        0,
        0,
        `text-${this.scene.game.currentLocale}-${this.declarationId}`,
        undefined
      )

      // make textSprite same size as heartSprite
      textSprite.displayWidth = heartSprite.displayWidth
      textSprite.scaleY = textSprite.scaleX

      // create render texture
      const renderTexture = this.scene.add.renderTexture(
        this.x,
        this.y,
        heartSprite.displayWidth,
        heartSprite.displayHeight
      )

      // draw heart & text sprites on it
      renderTexture.draw(
        heartSprite,
        renderTexture.displayWidth / 2,
        renderTexture.displayHeight / 2
      )
      renderTexture.draw(
        textSprite,
        renderTexture.displayWidth / 2,
        renderTexture.displayHeight / 2
      )

      // save texture in TextureManager
      renderTexture.saveTexture(textureKey)
      // destroy renderTexture
      renderTexture.destroy()
    }

    return textureKey
  }

  onCollideWithAnotherHeart() {
    // if collision effect enabled && no current animation playing
    if (
      this.scene.isHeartsCollisionEffectEnabled &&
      this.sparklesSprite &&
      !this.sparklesSprite?.anims.isPlaying
    ) {
      this.sparklesSprite.anims.play(`sparkles-${this.sparkles}`)
    }
  }

  synchSparklesSprites() {
    if (this.sparklesContainer && this.matterSprite) {
      // sync position
      this.sparklesContainer.setPosition(this.matterSprite.x, this.matterSprite.y)
      // sync scale
      this.sparklesContainer.setScale(this.matterSprite.scaleX, this.matterSprite.scaleY)
      // sync rotation
      this.sparklesContainer.setRotation(this.matterSprite.rotation)
    }
  }

  updateWolConfiguration() {
    this.velocityXAmplitude = getConfigurations()[this.scene.game.wolType].velocityXAmplitude
    this.velocityXFrenquency = getConfigurations()[this.scene.game.wolType].velocityXFrenquency
    this.velocityXTimeOffset = getConfigurations()[this.scene.game.wolType].velocityXTimeOffset
  }

  destroy() {
    if (this.matterSprite) {
      // remove from matter world
      this.scene.matter.world.remove(this.matterSprite)
      // destroy heart
      this.matterSprite.destroy()
    }
  }
}
