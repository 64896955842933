import { all, fork } from 'redux-saga/effects'

import ApiSagas from './api/sagas'
import AppSagas from './app/sagas'
import AuthSagas from './auth/sagas'
import ExampleSagas from './example/sagas'
import InactivitySagas from './inactivity/sagas'
import TranckingSagas from './tracking/sagas'

function* allListeners() {
  yield all([
    ApiSagas.listeners(),
    AppSagas.listeners(),
    AuthSagas.listeners(),
    ExampleSagas.listeners(),
    InactivitySagas.listeners(),
    TranckingSagas.listeners(),
  ])
}

export default function* rootSaga() {
  yield fork(allListeners)
}
