import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { gsap } from 'gsap'
import { Transition } from 'react-transition-group'

import { actions, selectors } from '../../../../redux'
import { Declaration, Step } from '../../../../types/global'
import WolSmallPodium from '../../../../templates/WolSmallPodium'

type WolSmallPodiumStepProps = {
  isVisible?: boolean
}

const WolSmallPodiumStep: React.FC<WolSmallPodiumStepProps> = ({ isVisible = true }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  // selectors

  const heartsQueue = useSelector(selectors.app.heartsQueue)

  const declarationLabels = useMemo(
    () =>
      Object.values(Declaration).reduce(
        (prev, currentValue) => ({
          ...prev,
          [currentValue]: t('heart.text', {
            preposition: t(`enum.declaration.preposition.${currentValue}` as any),
            subject: t(`enum.declaration.${currentValue}` as any),
          }),
        }),
        {} as { [key in Declaration]: string }
      ),
    [t]
  )

  // return

  return (
    <Transition
      in={isVisible}
      addEndListener={(node, done) => {
        node.addEventListener('gsapComplete', done, false)
      }}
      mountOnEnter={false}
      unmountOnExit={false}
      onEnter={(node: HTMLElement) => {
        gsap.fromTo(
          node,
          {
            opacity: 0,
          },
          {
            delay: 0.3,
            duration: 1.5,
            ease: 'none',
            opacity: 1,
            onComplete: () => {
              node.dispatchEvent(new Event('gsapComplete'))
            },
          }
        )
      }}
      onExit={(node: HTMLElement) => {
        gsap.to(node, {
          duration: 0.2,
          ease: 'power1.out',
          opacity: 0,
          overwrite: 'auto',
          onComplete: () => {
            node.dispatchEvent(new Event('gsapComplete'))
          },
        })
      }}
    >
      {(state) => (
        <WolSmallPodium
          isInitiallyTransparent={true}
          isClickable={isVisible}
          wolPhaser={{
            isActive: state === 'entering' || state === 'entered' || state === 'exiting',
            isDebug: false,
            width: window.innerWidth * 0.7,
            currentLocale: i18n.language,
            declarationLabels: declarationLabels,
            heartsQueue: heartsQueue,
            dispatch,
            onHeartSpawn: () => {
              dispatch(actions.app.onHeartSpawn())
            },
          }}
          title={t('wolSmallPodium.title')}
          onClick={() => {
            dispatch(actions.app.setCurrentStep(Step.landing))
          }}
        />
      )}
    </Transition>
  )
}

export default WolSmallPodiumStep
