import styled from 'styled-components'

import { theme } from '../../theme'
import Heart from '../../components/Heart'
import QrCode from '../../components/QrCode'

export const Content = styled.div`
  flex-grow: 1;
  padding: 9rem 2rem 2rem 2rem;
  overflow: hidden;
  position: relative;

  ${theme.breakpoints.up('tablet')} {
    display: flex;
    flex-direction: column;
    padding: 130px 40px 40px 50px;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    padding: 155px 40px 40px 60px;
  }
`

export const Title = styled.p`
  margin-right: auto;
  ${(props) => props.theme.fonts.FuturaLTProLight};
  color: ${(props) => props.theme.colors.gold};
  font-size: 4.8rem;
  text-transform: uppercase;
  line-height: 110%;
  letter-spacing: 0.04em;
  white-space: pre-wrap;

  ${theme.breakpoints.up('tablet')} {
    ${(props) => props.theme.textStyles.titleH1};
    font-size: 10rem;

    html[lang='es'] & {
      font-size: 8rem;
    }
  }

  ${theme.breakpoints.up('tabletLarge')} {
    ${(props) => props.theme.textStyles.titleH1}

    html[lang="es"] & {
      font-size: 10rem;
    }
  }
`

export const HeartC = styled(Heart)`
  position: relative;
  right: -20%;
  margin-left: auto;
  margin-top: -40px;
  width: 80vw;
  transform: rotate(15deg);

  ${theme.breakpoints.up('tablet')} {
    position: absolute;
    top: 51%;
    right: 17%;
    transform: rotate(15deg) translateY(-50%);
    width: 420px;
    margin-top: auto;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    top: 49%;
    right: 19%;
    width: 500px;
  }
`

export const TextTitle = styled.p`
  ${(props) => props.theme.textStyles.titleH5}
  color: ${(props) => props.theme.colors.gold};
  margin-top: auto;
  padding-top: 2rem;

  ${theme.breakpoints.up('tablet')} {
    ${(props) => props.theme.textStyles.titleH3}
  }
`

export const Text = styled.p`
  ${(props) => props.theme.textStyles.textSmall}
  color: ${(props) => props.theme.colors.gold};
  margin-top: 2.2rem;

  ${theme.breakpoints.up('tablet')} {
    ${(props) => props.theme.textStyles.text};
    margin-bottom: 60px;
    max-width: 42rem;
  }
`

// this is to preload QRCode image to fix image display on Safari on next QRCode

export const HiddenQRCode = styled(QrCode)`
  display: none;
  pointer-events: none;
`
