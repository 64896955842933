import React, { FC } from 'react'

import { WolPhaserProps } from '../../components/WolPhaser'
import { HeartCounterProps } from '../../components/HeartCounter'

import * as SC from './styled'

export type WolSmallPodiumTemplateProps = {
  className?: string
  wolPhaser: WolPhaserProps
  title: string
  heartCounter?: HeartCounterProps
  isClickable?: boolean
  isInitiallyTransparent?: boolean
  onClick?: () => void
}

const WolSmallPodiumTemplate: FC<WolSmallPodiumTemplateProps> = ({
  className,
  wolPhaser,
  title,
  isClickable = true,
  isInitiallyTransparent = false,
  onClick,
}) => {
  return (
    <SC.Content
      className={className}
      onClick={onClick}
      $isClickable={isClickable}
      $isInitiallyTransparent={isInitiallyTransparent}
    >
      <SC.Wol {...wolPhaser} $width={wolPhaser.width} />
      <SC.Title>{title}</SC.Title>
    </SC.Content>
  )
}

export default WolSmallPodiumTemplate
