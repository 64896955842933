import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as htmlToImage from 'html-to-image'
import { gsap } from 'gsap'

import retailers from '../../data/retailers.json'
import PersonalizationTemplate from '../../templates/Personalisation'
import { Color, Sparkles, Step } from '../../types/global'
import { colorsHex } from '../../helpers/ColorsHelpers'
import { actions, selectors } from '../../redux'
import { theme } from '../../theme'
import { HeartProps } from '../../components/Heart'
import { trackingEventNames } from '../../redux/tracking/constants'
import { routesPath } from '../../router'
import { useIsIOS } from '../../hooks/useIsIOS'
import * as configuration from '../../configuration'

const ShareLandingPage = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const isIos = useIsIOS()

  const [searchParams] = useSearchParams()

  const isSmallPodium = useSelector(selectors.app.isSmallPodium)

  const [isSharePending, setIsSharePending] = useState(true)
  const [shareFileArray, setShareFileArray] = useState<File[]>()
  const [isLocaleSet, setIsLocaleSet] = useState(false)

  const $container = useRef(null)

  const retailer = searchParams.get('r')
  const locale = searchParams.get('l')
  const color = searchParams.get('c') as Color
  const declaration = searchParams.get('d') as Color
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sparkles = searchParams.get('s') as Sparkles

  const isShareDownload = useMemo(() => location.pathname === routesPath.shareLandingDownload, [
    location.pathname,
  ])

  const heartProps: HeartProps = useMemo(
    () => ({
      color: colorsHex[color],
      text: t('heart.text', {
        preposition: t(`enum.declaration.preposition.${declaration}` as any),
        subject: t(`enum.declaration.${declaration}` as any),
      }),
      // sparkles: sparkles,
    }),
    [color, declaration, t]
  )

  useEffect(() => {
    if (retailer) {
      dispatch(actions.app.setRetailer(retailer))
    }
  }, [dispatch, retailer])

  useEffect(() => {
    dispatch(actions.app.setIsInStore(true))
  }, [dispatch, retailer])

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale).then(() => {
        setIsLocaleSet(true)
      })
    } else {
      setIsLocaleSet(true)
    }
  }, [i18n, locale])

  useEffect(() => {
    if (isLocaleSet) {
      dispatch(
        actions.tracking.pushEvent({
          event: trackingEventNames.pageView,
          data: {
            language: i18n.language,
            page: 'shareLanding',
          },
        })
      )
    }
  }, [dispatch, i18n.language, isLocaleSet])

  useEffect(() => {
    setTimeout(async () => {
      const heartElement = document.querySelector('.share-heart') as HTMLElement
      const blob = await htmlToImage.toBlob(heartElement, {
        backgroundColor: theme.colors.beige,
        pixelRatio: 2,
        quality: 0.9,
      })
      const filesArray = [
        new File([blob!], 'heart.png', {
          type: blob?.type,
          lastModified: new Date().getTime(),
        }),
      ]

      setShareFileArray(filesArray)

      setIsSharePending(false)
    }, 1500)
  }, [])

  useEffect(() => {
    gsap.to($container.current, {
      opacity: 1,
      delay: 0.5,
      duration: 1,
      ease: 'power1.out',
    })
  }, [])

  return (
    <div ref={$container} style={{ height: '100%', opacity: 0 }}>
      <PersonalizationTemplate
        heart={heartProps}
        heartForSharing={{
          heart: heartProps,
          containerClassName: 'share-heart',
        }}
        shareStep={{
          isVisible: true,
          isShareLanding: true,
          isSmallPodium,
          mobileButton: {
            label: isShareDownload
              ? t('share.mobile.downloadButton.label')
              : t('share.mobile.shareButton.label'),
            isDisabled: isSharePending,
            onClick: () => {
              if (isShareDownload) {
                navigator.share({
                  files: shareFileArray,
                })
              } else {
                dispatch(
                  actions.tracking.pushEvent({
                    event: trackingEventNames.shareHeart,
                  })
                )
                navigator.share({
                  files: shareFileArray,
                  text: t('sharing.text'),
                  url: isIos
                    ? `${configuration.app.WEBSITE_URL}${routesPath.shareLandingDownload}?l=${i18n.language}&c=${color}&d=${declaration}`
                    : window.location.origin + `/#/?l=${locale}`,
                })
              }
            },
          },
          mobileLinkButton: retailer
            ? {
                label: t('share.mobile.linkButton.label', {
                  retailer: retailers.find((r) => r.id === retailer)?.name,
                }),
                href: retailers.find((r) => r.id === retailer)?.url,
                target: '_blank',
                onClick: () => {
                  dispatch(
                    actions.tracking.pushEvent({
                      event: trackingEventNames.shopNow,
                    })
                  )
                },
              }
            : undefined,
          mobileRestartButton: {
            label: t('share.mobile.restart'),
            onClick: () => {
              dispatch(
                actions.tracking.pushEvent({
                  event: trackingEventNames.restartTheExperience,
                })
              )
              gsap.to($container.current, {
                opacity: 0,
                ease: 'power1.out',
                duration: 0.3,
                onComplete: () => {
                  dispatch(actions.app.setCurrentStep(Step.selection))
                  navigate('/')
                },
              })
            },
          },
          socials: {
            label: t('share.socials.label'),
            items: [
              {
                icon: {
                  icon: 'instagram',
                },
                link: {
                  href: t('share.socials.intagram.link'),
                  target: '_blank',
                  onClick: () => {
                    dispatch(
                      actions.tracking.pushEvent({
                        event: trackingEventNames.followDGBeauty,
                      })
                    )
                  },
                },
              },
            ],
          },
        }}
      />
    </div>
  )
}

export default ShareLandingPage
