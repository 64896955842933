import { useEffect } from 'react'

export function useListenReactNativeMessage(callBack: (message: any) => void) {
  useEffect(() => {
    const handler = (message: MessageEvent) => {
      const isMessageFromReactNativeApp = JSON.stringify(message.data).includes('fromReactNative')
      if (isMessageFromReactNativeApp) {
        const messageParsed = JSON.parse(message.data)
        callBack(messageParsed)
      }
    }
    window.addEventListener('message', handler)
    return () => {
      window.removeEventListener('message', handler)
    }
  }, [callBack])
}
