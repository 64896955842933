import { RouteType } from '../types/routes'
import RootPage from '../pages/Root'
import WolPage from '../pages/Wol'
import ShareLandingPage from '../pages/ShareLanding'

import { routesPath } from './index'

//
// const publicRoutes: RouteType = {
//   path: '',
//   component: null,
//   guard: GuestGuard,
//   children: [
//     {
//       path: routesPath.login,
//       name: 'Login',
//       component: LoginPage,
//     },
//   ],
// }

const privateRoutes: RouteType = {
  path: '',
  component: null,
  // guard: AuthGuard,
  children: [
    {
      path: routesPath.root,
      name: 'Root',
      component: RootPage,
    },
    {
      path: routesPath.wol,
      name: 'Wol',
      component: WolPage,
    },
    {
      path: routesPath.shareLanding,
      name: 'Share',
      component: ShareLandingPage,
    },
    {
      path: routesPath.shareLandingDownload,
      name: 'Share download',
      component: ShareLandingPage,
    },
  ],
}

export const defaultLayoutRoutes = [privateRoutes]
