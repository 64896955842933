import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as htmlToImage from 'html-to-image'

import PersonalizationTemplate from '../../../../templates/Personalisation'
import { theme } from '../../../../theme'
import { Color, Declaration, Sparkles, Step } from '../../../../types/global'
import { colorsHex, sparklesHex } from '../../../../helpers/ColorsHelpers'
import { actions, selectors } from '../../../../redux'
import * as configuration from '../../../../configuration'
import { routesPath } from '../../../../router'
import useWindowSize from '../../../../hooks/useWindowSize'
import { trackingEventNames } from '../../../../redux/tracking/constants'
import retailers from '../../../../data/retailers.json'

const PersonalizationStep = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const isSmallPodium = useSelector(selectors.app.isSmallPodium)
  const currentStep = useSelector(selectors.app.currentStep)
  const isInStore = useSelector(selectors.app.isInStore)
  const retailer = useSelector(selectors.app.retailer)
  const [selectedColor, setSelectedColor] = useState<Color>(Color.speranza)
  const [selectedSparkles, setSelectedSparkles] = useState<Sparkles>()
  const [selectedDeclaration, setSelectedDeclaration] = useState<Declaration>(Declaration.lovedOne)
  const [isSharePending, setIsSharePending] = useState(true)
  const [shareFileArray, setShareFileArray] = useState<File[]>()

  const windowSize = useWindowSize()

  const isMobile = useMemo(() => {
    return windowSize.width < theme.breakpoints.values.tablet
  }, [windowSize])

  const sparkles = useMemo(() => {
    if ([Step.personalizationSparkles].includes(currentStep)) {
      return selectedSparkles
    }
  }, [currentStep, selectedSparkles])

  const heartText = useMemo(() => {
    switch (currentStep) {
      case Step.personalizationColor:
      case Step.personalizationSparkles:
        return t('personalization.heart.initialText')
      case Step.personalizationText:
      case Step.personalizationShare:
        return t('heart.text', {
          preposition: t(`enum.declaration.preposition.${selectedDeclaration}` as any),
          subject: t(`enum.declaration.${selectedDeclaration}` as any),
        })
    }
  }, [currentStep, selectedDeclaration, t])

  useEffect(() => {
    if (isMobile) {
      switch (currentStep) {
        case Step.personalizationShare:
          setTimeout(async () => {
            const heartElement = document.querySelector('.share-heart') as HTMLElement
            const blob = await htmlToImage.toBlob(heartElement, {
              backgroundColor: theme.colors.beige,
              pixelRatio: 2,
              quality: 0.9,
            })
            const filesArray = [
              new File([blob!], 'heart.png', {
                type: blob?.type,
                lastModified: new Date().getTime(),
              }),
            ]

            setShareFileArray(filesArray)

            setIsSharePending(false)
          }, 600) // wait exit duration previous step + enter duration current step
          break
        default:
          setIsSharePending(true)
          setShareFileArray(undefined)
      }
    }
  }, [currentStep, isMobile])

  return (
    <PersonalizationTemplate
      heart={{
        color: colorsHex[selectedColor],
        text: heartText,
        sparkles,
      }}
      heartForSharing={
        isMobile
          ? {
              heart: {
                color: colorsHex[selectedColor],
                text: heartText,
                sparkles,
              },
              containerClassName: 'share-heart',
            }
          : undefined
      }
      colorStep={{
        isVisible: currentStep === Step.personalizationColor,
        title: t('personalization.colorStep.title'),
        options: [
          {
            name: Color.speranza,
          },
          {
            name: Color.rispetto,
          },
          {
            name: Color.generosita,
          },
          {
            name: Color.gratitudine,
          },
          {
            name: Color.affetto,
          },
          {
            name: Color.felicita,
          },
          {
            name: Color.orgoglio,
          },
          {
            name: Color.devozione,
          },
          {
            name: Color.audacia,
          },
        ].map((option) => ({
          ...option,
          label: t(`enum.color.${option.name}` as any),
          checked: selectedColor === option.name,
          onChange: (e) => {
            if (e.target.checked) {
              setSelectedColor(e.target.name as Color)
            }
          },
          textColor: theme.colors.beige,
          checkedTextColor: theme.colors.pureBlack,
          checkedBorderColor: theme.colors.pureBlack,
          backgroundColor: colorsHex[option.name],
          checkedBackgroundColor: colorsHex[option.name],
        })),
        button: {
          label: t('personalization.colorStep.cta'),
          onClick: () => {
            dispatch(actions.app.setCurrentStep(Step.personalizationSparkles))
          },
        },
      }}
      sparklesStep={{
        isVisible: currentStep === Step.personalizationSparkles,
        title: t('personalization.sparklesStep.title'),
        options: [
          {
            name: Sparkles.citrus,
          },
          {
            name: Sparkles.orange,
          },
          {
            name: Sparkles.vanilla,
          },
        ].map((option) => ({
          ...option,
          label: t(`enum.sparkles.${option.name}` as any),
          checked: selectedSparkles === option.name,
          onChange: (e) => {
            if (e.target.checked) {
              setSelectedSparkles(e.target.name as Sparkles)
            }
          },
          textColor: theme.colors.pureBlack,
          checkedTextColor: theme.colors.pureBlack,
          checkedBorderColor: theme.colors.pureBlack,
          backgroundColor: sparklesHex[option.name],
          checkedBackgroundColor: sparklesHex[option.name],
        })),
        button: {
          label: t('personalization.sparklesStep.cta'),
          onClick: () => {
            dispatch(actions.app.setCurrentStep(Step.personalizationText))
          },
        },
      }}
      textStep={{
        isVisible: currentStep === Step.personalizationText,
        title: t('personalization.textStep.title'),
        options: [
          {
            name: Declaration.lovedOne,
          },
          {
            name: Declaration.family,
          },
          {
            name: Declaration.bestFriend,
          },
          {
            name: Declaration.lifePartner,
          },
          {
            name: Declaration.myself,
          },
          {
            name: Declaration.city,
          },
          {
            name: Declaration.origins,
          },
          {
            name: Declaration.traditions,
          },
          {
            name: Declaration.future,
          },
          {
            name: Declaration.moment,
          },
        ].map((option) => ({
          ...option,
          label: t(`enum.declaration.${option.name}` as any),
          checked: selectedDeclaration === option.name,
          onChange: (e) => {
            if (e.target.checked) {
              setSelectedDeclaration(e.target.name as Declaration)
            }
          },
          textColor: theme.colors.gold,
          checkedTextColor: theme.colors.pureWhite,
          checkedBorderColor: theme.colors.gold,
          backgroundColor: theme.colors.pureWhite,
          checkedBackgroundColor: theme.colors.gold,
        })),
        button: {
          label: t('personalization.textStep.cta'),
          onClick: () => {
            dispatch(actions.app.setCurrentStep(Step.personalizationShare))
          },
        },
      }}
      shareStep={{
        isVisible: currentStep === Step.personalizationShare,
        isSmallPodium,
        // mobileTitle: 'Declare My Love',
        qrCode: {
          url: `${configuration.app.WEBSITE_URL}${routesPath.shareLanding}?l=${i18n.language}${
            retailer ? `&r=${retailer}` : ''
          }&c=${selectedColor}&d=${selectedDeclaration}`,
        },
        mobileButton: {
          label: t('share.mobile.shareButton.label'),
          isDisabled: isSharePending,
          onClick: () => {
            dispatch(
              actions.tracking.pushEvent({
                event: trackingEventNames.shareHeart,
              })
            )
            navigator.share({
              files: shareFileArray,
              text: t('sharing.text'),
              url: window.location.origin + `/#/?l=${i18n.language}`,
            })
          },
        },
        mobileLinkButton:
          isInStore && !retailer
            ? undefined
            : {
                label: t('share.mobile.linkButton.label', {
                  retailer: retailer
                    ? retailers.find((r) => r.id === retailer)?.name
                    : 'Dolce&Gabbana',
                }),
                href: retailer
                  ? retailers.find((r) => r.id === retailer)?.url
                  : t('share.mobile.linkButton.link'),
                target: '_blank',
                onClick: () => {
                  dispatch(
                    actions.tracking.pushEvent({
                      event: trackingEventNames.shopNow,
                    })
                  )
                },
              },
        mobileRestartButton: {
          label: t('share.mobile.restart'),
          onClick: () => {
            dispatch(
              actions.tracking.pushEvent({
                event: trackingEventNames.restartTheExperience,
              })
            )
            dispatch(actions.app.setCurrentStep(Step.landing))
          },
        },
        desktopButton: {
          label: t('share.shareButton.label'),
          onClick: () => {
            dispatch(
              actions.app.sendHeartToWol({
                color: selectedColor,
                declaration: selectedDeclaration,
                sparkles: selectedSparkles,
              })
            )
          },
        },
        qrCodeText: t('share.qrCode.text'),
        socials: {
          label: t('share.socials.label'),
          items: [
            {
              icon: {
                icon: 'instagram',
              },
              link: {
                href: t('share.socials.intagram.link'),
                target: '_blank',
                onClick: () => {
                  dispatch(
                    actions.tracking.pushEvent({
                      event: trackingEventNames.followDGBeauty,
                    })
                  )
                },
              },
            },
          ],
        },
      }}
    />
  )
}

export default PersonalizationStep
