import { all, takeEvery } from 'redux-saga/effects'

import { actions } from '../index'

export default class TranckingSagas {
  static onPushEvent({ payload }: ReturnType<typeof actions.tracking.pushEvent>) {
    const data = payload.data ?? {}

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: payload.event,
      ...data,
    })
  }

  static *listeners() {
    yield all([takeEvery(actions.tracking.pushEvent, this.onPushEvent)])
  }
}
