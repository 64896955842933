import { css } from 'styled-components'

// TYPES

export enum FontWeights {
  thin = 100,
  extraLight = 200,
  light = 300,
  regular = 400,
  medium = 500,
  semiBold = 600,
  bold = 700,
  extraBold = 800,
  black = 900,
}

export enum FontStyles {
  normal = 'normal',
  italic = 'italic',
  oblique = 'oblique',
}

type Font = {
  basename: string
  fontFamily: string
  fontWeight?: FontWeights
  fontStyle?: FontStyles
  fallback?: string
}

enum FontKeys {
  FuturaLTProLight = 'FuturaLTProLight',
  FuturaLTProBook = 'FuturaLTProBook',
  FuturaLTProMedium = 'FuturaLTProMedium',
  PoppinsRegular = 'PoppinsRegular',
  PoppinsBold = 'PoppinsBold',
}

// DECLARATIONS

export const declarations: { [key in FontKeys]: Font } = {
  FuturaLTProLight: {
    basename: 'FuturaLTPro-Light',
    fontFamily: 'FuturaLTPro',
    fontWeight: FontWeights.light,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  FuturaLTProBook: {
    basename: 'FuturaLTPro-Book',
    fontFamily: 'FuturaLTPro',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  FuturaLTProMedium: {
    basename: 'FuturaLTPro-Medium',
    fontFamily: 'FuturaLTPro',
    fontWeight: FontWeights.medium,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsRegular: {
    basename: 'Poppins-Regular',
    fontFamily: 'Poppins',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsBold: {
    basename: 'Poppins-Bold',
    fontFamily: 'Poppins',
    fontWeight: FontWeights.bold,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
}

// @FONT-FACE

export const fontsFaces: any[] = Object.values(declarations)?.map(
  (declaration) => css`
    @font-face {
      font-family: ${declaration.fontFamily};
      font-display: swap;
      font-weight: ${declaration.fontWeight || FontWeights.regular};
      font-style: ${declaration.fontStyle || FontStyles.normal};
      src: url('/fonts/${declaration.basename}/${declaration.basename}.woff2') format('woff2');
    }
  `
)

// CSS

const fontCSS = (font: Font): string => `
    font-family: "${font.fontFamily}", ${font.fallback || 'sans-serif'};
    font-weight: ${font.fontWeight || FontWeights.regular};
    font-style: ${font.fontStyle || FontStyles.normal};
  `

const fonts: { [key in FontKeys]: string } = Object.keys(declarations)?.reduce(
  (acc, fontKey) => ({
    ...acc,
    [fontKey]: fontCSS(declarations[fontKey as FontKeys]),
  }),
  {} as any
)

export default fonts
