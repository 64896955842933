import React, { FC, useEffect, useRef, useState } from 'react'
import QRCodeStyling from 'qr-code-styling'

import { theme } from '../../theme'

import * as SC from './styled'

export type QrCodeProps = {
  className?: string
  url?: string
  color?: string
}

const QrCode: FC<QrCodeProps> = ({ className, url, color = theme.colors.gold }) => {
  const $qrCode = useRef<QRCodeStyling | null>(null)
  const $wrapper = useRef<HTMLDivElement>(null)
  const [isQrCodeInstanceCreated, setIsQrCodeInstanceCreated] = useState(false)

  useEffect(() => {
    $qrCode.current = new QRCodeStyling({
      width: 400,
      height: 400,
      backgroundOptions: {
        color: 'transparent',
      },
      dotsOptions: {
        type: 'extra-rounded',
      },
      qrOptions: {
        errorCorrectionLevel: 'Q',
      },
      image: '/images/logo-dg.png',
      imageOptions: {
        imageSize: 0.5,
        margin: 15,
      },
    })
    setIsQrCodeInstanceCreated(true)
  }, [])

  useEffect(() => {
    if (isQrCodeInstanceCreated) {
      $qrCode.current!.update({ data: url })
    }
  }, [isQrCodeInstanceCreated, url])

  useEffect(() => {
    if (isQrCodeInstanceCreated && color) {
      $qrCode.current!.update({ dotsOptions: { color: color } })
    }
  }, [color, isQrCodeInstanceCreated])

  useEffect(() => {
    if (isQrCodeInstanceCreated && $wrapper.current) {
      $qrCode.current!.append($wrapper.current)
    }
  }, [isQrCodeInstanceCreated])

  return <SC.QrCode className={className} ref={$wrapper} />
}

export default QrCode
