import styled, { css } from 'styled-components'

import Icon from '../Icon'
import Link from '../Link'
import { theme } from '../../theme'

import type { ActionButtonProps } from '.'

export const ActionButton = styled(Link)<{
  $disabled?: boolean
  $variant?: ActionButtonProps['variant']
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  border-radius: 5rem;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;

  ${(props) => {
    switch (props.$variant) {
      case 'primary':
        return css`
          padding: 1.2rem 3.6rem;
          border: 2px solid ${(props) => props.theme.colors.gold};
          color: ${(props) => props.theme.colors.gold};
          background-color: transparent;
        `
      case 'primaryWithBackground':
        return css`
          padding: 1.2rem 3.6rem;
          border: 2px solid ${(props) => props.theme.colors.gold};
          color: ${(props) => props.theme.colors.beige};
          background-color: ${(props) => props.theme.colors.gold};
        `
      case 'primaryWithoutBackground':
        return css`
          border: none;
          padding: 0;
          color: ${(props) => props.theme.colors.gold};
        `
    }
  }}

  ${theme.breakpoints.up('tablet')} {
    gap: 0.8rem;
    ${(props) => {
      switch (props.$variant) {
        case 'primary':
        case 'primaryWithBackground':
          return css`
            padding: 1.2rem 3.3rem;
          `
      }
    }}
  }

  ${theme.breakpoints.up('tabletLarge')} {
    gap: 0.8rem;
    ${(props) => {
      switch (props.$variant) {
        case 'primary':
        case 'primaryWithBackground':
          return css`
            padding: 1.6rem 3.6rem;
          `
      }
    }}
  }

  ${(props) =>
    props.$disabled &&
    css`
      opacity: 0.4;
      transition: opacity 0.3s;
      pointer-events: none;
    `}

  ${(props) =>
    props.$disabled === false &&
    css`
      transition: opacity 0.3s;
    `}
`

export const Label = styled.span<{ $isUppercase: boolean }>`
  ${({ theme }) => theme.textStyles.titleH6};
  text-transform: ${(props) => (props.$isUppercase ? 'uppercase' : 'none')};
  display: flex;
  align-items: center;
  //min-height: 2.4rem;

  ${theme.breakpoints.up('tablet')} {
    ${({ theme }) => theme.textStyles.titleH5};
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  ${theme.breakpoints.up('tabletLarge')} {
    ${({ theme }) => theme.textStyles.titleH5};
    line-height: 2.8rem;
  }
`

export const ActionIcon = styled(Icon)`
  width: 2.2rem;
  height: 2.2rem;
  ${theme.breakpoints.up('tablet')} {
    width: 2.8rem;
    height: 2.8rem;
  }
`
