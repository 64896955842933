import styled from 'styled-components'

import Heart from '../Heart'

export const PhaserGameParent = styled.div`
  position: relative;
  z-index: 2;
`

export const BlurGradient = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  backdrop-filter: blur(4px);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 30%);
  pointer-events: none;
`

export const ColorGradient = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${(props) =>
    `linear-gradient(to bottom, ${props.theme.colors.beige} 0%, ${props.theme.colors.beige75} 14%, rgba(0, 0, 0, 0) 35%)`};
  pointer-events: none;
`

export const HeartTexts = styled.div`
  position: absolute;
  width: 330px;
  height: 330px;
  z-index: 1;
  top: 0;
  left: 0;
`

export const HeartText = styled(Heart)`
  display: block;
  width: 100%;
  height: 100%;
`
