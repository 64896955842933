import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteProps, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SwitchTransition, Transition } from 'react-transition-group'
import { gsap } from 'gsap'
import { useTranslation } from 'react-i18next'

import { actions, selectors } from '../../redux'
import { ReactNativeMessages, Step } from '../../types/global'
import DefaultLayout from '../../layouts/DefaultLayout'
import useWindowSize from '../../hooks/useWindowSize'
import { theme } from '../../theme'
import { useListenReactNativeMessage } from '../../hooks/useListenReactNativeMessage'
import { resources } from '../../i18n'
import { trackingEventNames } from '../../redux/tracking/constants'

import LandingStep from './steps/landing'
import SelectionStep from './steps/selection'
import PersonalizationStep from './steps/personalization'
import IconicShareStep from './steps/iconicShare'
import InactivityContainer from './Inactivity'
import WolSmallPodiumStep from './steps/wolSmallPodium'

const RootPage: React.FC<RouteProps> = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const [searchParams] = useSearchParams()
  const hasLargePodiumSearchParams = searchParams.has('largePodium')
  const hasInStoreParams = searchParams.has('inStore')
  const localeSearchParams = searchParams.get('l')
  const retailerSearchParams = searchParams.get('r')

  const windowSize = useWindowSize()

  // selectors

  const isSmallPodium = useSelector(selectors.app.isSmallPodium)
  const currentStep = useSelector(selectors.app.currentStep)
  const heartsQueue = useSelector(selectors.app.heartsQueue)

  // state

  const [isLocaleSet, setIsLocaleSet] = useState(false)

  // var

  const hasBackButton = useMemo(
    () =>
      [
        Step.selection,
        Step.iconicShare,
        Step.personalizationColor,
        Step.personalizationSparkles,
        Step.personalizationText,
        Step.personalizationShare,
      ].includes(currentStep),
    [currentStep]
  )

  const hasRestartButton = useMemo(() => {
    switch (currentStep) {
      case Step.personalizationColor:
      case Step.personalizationSparkles:
      case Step.personalizationText:
        return true
      case Step.iconicShare:
      case Step.personalizationShare:
        return windowSize?.width >= theme.breakpoints.values.tablet
      default:
        return false
    }
  }, [currentStep, windowSize?.width])

  const hasHeartCount = useMemo(() => {
    switch (currentStep) {
      case Step.landing:
      case Step.selection:
      case Step.personalizationColor:
      case Step.personalizationSparkles:
      case Step.personalizationText:
        return !isSmallPodium
      case Step.iconicShare:
      case Step.personalizationShare:
      case Step.wolSmallPodium:
        return true
      default:
        return false
    }
  }, [currentStep, isSmallPodium])

  const hasLocalesSelect = useMemo(() => {
    switch (currentStep) {
      case Step.landing:
        return true
      default:
        return false
    }
  }, [currentStep])

  const restartLabel = useMemo(() => {
    switch (currentStep) {
      default:
      case Step.landing:
      case Step.selection:
      case Step.personalizationColor:
      case Step.personalizationSparkles:
      case Step.personalizationText:
        return t('layout.restart')
      case Step.iconicShare:
      case Step.personalizationShare:
        return t('layout.newHeart')
    }
  }, [currentStep, t])

  // EFFECTS

  // set local from params
  useEffect(() => {
    if (localeSearchParams) {
      i18n.changeLanguage(localeSearchParams).then(() => {
        setIsLocaleSet(true)
      })
    } else {
      setIsLocaleSet(true)
    }
  }, [i18n, localeSearchParams])

  useEffect(() => {
    if (isLocaleSet) {
      dispatch(
        actions.tracking.pushEvent({
          event: trackingEventNames.pageView,
          data: {
            language: i18n.language,
            page: currentStep,
          },
        })
      )
    }
  }, [currentStep, dispatch, i18n.language, isLocaleSet])

  // set retailer from params
  useEffect(() => {
    if (retailerSearchParams) {
      dispatch(actions.app.setRetailer(retailerSearchParams))
    }
  }, [dispatch, retailerSearchParams])

  // listen message from react-native app

  useListenReactNativeMessage(
    useCallback(
      (message) => {
        if (message.id === ReactNativeMessages.heartSpawn) {
          // on heart spawn, if not small podium, move first heart to end of queue
          if (!isSmallPodium) {
            dispatch(actions.app.moveFirstHeartToEndOfQueue())
          }
        }
        if (message.id === ReactNativeMessages.hasExternalDisplay) {
          // if it has not external display -> set is small podium
          dispatch(actions.app.setIsSmallPodium(!message.hasExternalDisplay))
        }
      },
      [dispatch, isSmallPodium]
    )
  )

  // force large podium if search params
  useEffect(() => {
    if (hasLargePodiumSearchParams) {
      dispatch(actions.app.setIsSmallPodium(false))
    }
  }, [dispatch, hasLargePodiumSearchParams])

  // set is in store from params
  useEffect(() => {
    if (hasInStoreParams) {
      dispatch(actions.app.setIsInStore(true))
    }
  }, [dispatch, hasInStoreParams, hasLargePodiumSearchParams])

  // is current step is wolSmallPodium and not small podium anymore (on screen disconect for example) -> go to landing step
  useEffect(() => {
    if (currentStep === Step.wolSmallPodium && !isSmallPodium) {
      dispatch(actions.app.setCurrentStep(Step.landing))
    }
  }, [currentStep, dispatch, isSmallPodium])

  const currentStepKey = useMemo(() => {
    switch (currentStep) {
      case Step.landing:
        return 'landing'
      case Step.selection:
        return 'selection'
      case Step.iconicShare:
        return 'iconicShare'
      case Step.personalizationColor:
      case Step.personalizationSparkles:
      case Step.personalizationText:
      case Step.personalizationShare:
        return 'personalization'
      case Step.wolSmallPodium:
        return 'wolSmallPodium'
    }
  }, [currentStep])

  // renderers

  const renderStep = useMemo(() => {
    switch (currentStep) {
      case Step.landing:
        return <LandingStep />
      case Step.selection:
        return <SelectionStep />
      case Step.iconicShare:
        return <IconicShareStep />
      case Step.personalizationColor:
      case Step.personalizationSparkles:
      case Step.personalizationText:
      case Step.personalizationShare:
        return <PersonalizationStep />
      case Step.wolSmallPodium:
        return <div />
      default:
        return null
    }
  }, [currentStep])

  // return

  return (
    <DefaultLayout
      backButton={{
        isVisible: hasBackButton,
        props: {
          text: t('layout.back'),
          onClick: () => {
            switch (currentStep) {
              case Step.selection:
                dispatch(actions.app.setCurrentStep(Step.landing))
                break
              case Step.personalizationColor:
              case Step.iconicShare:
                dispatch(actions.app.setCurrentStep(Step.selection))
                break
              case Step.personalizationSparkles:
                dispatch(actions.app.setCurrentStep(Step.personalizationColor))
                break
              case Step.personalizationText:
                dispatch(actions.app.setCurrentStep(Step.personalizationSparkles))
                break
              case Step.personalizationShare:
                dispatch(actions.app.setCurrentStep(Step.personalizationText))
                break
            }
          },
        },
      }}
      restartButton={{
        isVisible: hasRestartButton,
        props: {
          text: restartLabel,
          onClick: () => {
            dispatch(
              actions.tracking.pushEvent({
                event: trackingEventNames.restartTheExperience,
              })
            )
            dispatch(actions.app.setCurrentStep(Step.landing))
          },
        },
      }}
      heartCounter={{
        isVisible: hasHeartCount,
        props: {
          text: t('layout.heartCount'),
          number: String(heartsQueue.length),
        },
      }}
      localesSelect={{
        isVisible: hasLocalesSelect,
        props: {
          name: 'local',
          value: i18n.language,
          options: Object.keys(resources).map((locale) => ({
            label: locale,
            value: locale,
          })),
          onChange: (e) => {
            i18n.changeLanguage(e.target.value)
          },
        },
      }}
    >
      <SwitchTransition mode={'out-in'}>
        <Transition
          key={currentStepKey}
          addEndListener={(node, done) => {
            node.addEventListener('gsapComplete', done, false)
          }}
          onEnter={(node: HTMLElement) => {
            gsap.fromTo(
              node,
              {
                opacity: 0,
              },
              {
                duration: 0.4,
                ease: 'none',
                opacity: 1,
              }
            )
          }}
          onExit={(node: HTMLElement) => {
            gsap.to(node, {
              duration: 0.2,
              ease: 'none',
              opacity: 0,
              onComplete: () => {
                node.dispatchEvent(new Event('gsapComplete'))
              },
            })
          }}
        >
          {renderStep}
        </Transition>
      </SwitchTransition>

      {windowSize?.width >= theme.breakpoints.values.tablet && isSmallPodium && (
        <WolSmallPodiumStep isVisible={currentStep === Step.wolSmallPodium} />
      )}

      {windowSize?.width >= theme.breakpoints.values.tablet && <InactivityContainer />}
    </DefaultLayout>
  )
}

export default RootPage
