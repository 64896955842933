import styled, { keyframes } from 'styled-components'

import { theme } from '../../theme'
import ActionButton from '../ActionButton'

const appearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
export const Inactivity = styled.div`
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  animation: ${appearAnimation} 0.5s 0.2s ease-out forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem;
  max-width: 60rem;
  width: 100%;
  text-align: center;
  background-color: ${(props) => props.theme.colors.pureWhite};
`

export const Title = styled.h1`
  ${theme.textStyles.titleH2};
  color: ${(props) => props.theme.colors.gold};
  text-align: center;
  margin: 0;
`

export const Text = styled.p`
  ${theme.textStyles.text};
  color: ${(props) => props.theme.colors.gold};
  margin: 1.5rem 0 0 0;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 5rem;
`

export const Button = styled(ActionButton)``
